import React from 'react'

interface InvestmentStepperProps {
  currentStep: string;
  steps: string[];
}

const InvestmentStepper: React.FC<InvestmentStepperProps> = ({ currentStep, steps }) => {
  return (
    <div className='bg-[#F2F2EF] p-4 rounded-lg shadow-md w-full '>
      {steps.map((step, index) => (
        <div key={index}
             className={`flex my-[6px] text-[17px] leading-[24px] ${step === currentStep ? 'font-semibold text-black' : 'text-[# 525252]'}`}>
          <div className={`w-1 rounded-[12px]  ${step === currentStep ? 'bg-[#37992E]' : 'bg-[#00000016]'}`}></div>
          <div className='ml-6 py-6 '>
            <span className='mr-2  '>{String(index + 1).padStart(2, '0')}</span>
            {step}
          </div>
        </div>
      ))}
    </div>
  )
}

export default InvestmentStepper
