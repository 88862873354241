import React from 'react'
import MainBackground from '../../assets/img/main-background.webp'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'



const MainBlock: React.FC = () => {
  const navigate = useNavigate()
  return <div className={'w-full bg-white flex items-center justify-center sm:px-12 '}>
    <div
      className={'container w-full flex items-center justify-center sm:rounded-[12px] py-[120px] max-sm:py-[80px] lg:py-[160px] max-sm:px-4 sm:pb-[100px] max-w-[1400px]'}
      style={{
        backgroundImage: `url(${MainBackground})`,
        backgroundRepeat: 'none',
        backgroundSize: 'cover',
      }}
    >
      <div className={'sm:px-[92px] w-full flex flex-col items-center justify-center gap-1'}>
      <div
    className='w-full text-center text-white lg:text-[48px] md:text-[46px] sm:text-[44px] text-[42px] lg:leading-[52px] md:leading-[48px] sm:leading-[44px] leading-[40px] animate__animated animate__fadeInUp animate__delay-1s font-extrabold font-Inter mb-4'>
    Invest in Swiss renewable<br/>energy projects
      </div>
      <div
    className='text-center text-white text-[20px] font-medium animate__animated animate__fadeInUp animate__delay-1s font-Inter sm:w-7/12 w-full mb-10'>
    In one of the most stable markets in the world
      </div>
        <a href={'#signup'} onClick={() => {
          ReactGA.event({
            category: 'HP_BTN_CALCULATE',
            action: 'HP_BTN_CALCULATE',
            label: 'HP_BTN_CALCULATE',
          })
        }
        }
           className='sm:h-[64px] sm:w-[388px] h-[58px] w-[287px] cursor-pointer px-6 py-4 bg-[#E3E3DD] animate__animated animate__fadeInUp animate__delay-1s rounded-xl shadow justify-center items-center gap-2 inline-flex'>
          <div
              className='text-center text-stone-950 text-[15px] font-bold font-Inter uppercase tracking-wide'>Become an Investor
          </div>
        </a>

        <div className={'w-full sm:w-8/12 sm:mx-auto flex flex-col  gap-[42px]'}>
    
        </div>

      </div>
    </div>
  </div>
}

export default MainBlock