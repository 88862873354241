import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import Button from '../../../LandingV3/components/Button'
import Arrow from '../../../LandingV3/icons/Arrow'
import KevinVertical from '../../assets/img/kevin.webp'
import { animated, useSpring } from '@react-spring/web'


const Video = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isExpanded, setExpanded] = useState(false)

  const videoStyle = useSpring({
    width: isExpanded ? '100%' : '50%',
    height: '100%',
    minHeight: '600px',
    config: { duration: 1000 },
  })

  const handlePlayClick = async () => {
    setExpanded(true)
    if (window.location.href.includes('results')) {
      ReactGA.event({
        category: 'RP_VIDEO_PLAY',
        action: 'RP_VIDEO_PLAY',
        label: 'RP_VIDEO_PLAY',
      })
    } else {
      ReactGA.event({
        category: 'HP_VIDEO_PLAY',
        action: 'HP_VIDEO_PLAY',
        label: 'HP_VIDEO_PLAY',
      })
    }

    setTimeout(() => {
      const videoToPlay = document.getElementById('responsiveVideoVertical') as HTMLVideoElement | null
      if (videoToPlay) {
        videoToPlay.play().then(() => {
          setIsPlaying(true)
        }).catch(error => {
          console.error('Error attempting to play the video:', error)
        })
      }
    }, 0)

  }


  return (
    <div className={'w-full flex items-center justify-center max-sm:hidden sm:px-16'}>
      <div
        className={`w-full max-w-[1400px]  flex-row relative rounded-[12px] max-h-[600px] ${isPlaying ? ' bg-white ' : 'bg-[#E5E5DF]'}  ${isExpanded ? 'justify-end ' : 'justify-start'} flex-grow items-center inline-flex container `}>
        {!isExpanded && (
          <div className='flex-col justify-start  w-1/2 items-start pl-10 pr-12 gap-6 flex  py-[124px] '>
            <div className='flex-col justify-start items-start gap-2 flex'>
              <div
                className='text-[#37992E] text-xs font-bold uppercase tracking-wide'>Renewable energy is the opportunity of our lifetime
              </div>
              <div
                className='xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px] text-start font-semibold  w-10/12 font-Inter'
              >
                Realise the rare convergence of profit and purpose
              </div>
            </div>
            <div
              className='text-start  text-[#525252] mb-4 text-[17px] font-normal font-Inter w-10/12 leading-[24px]'>Pyrpose represents a moment to influence change through the power of conscientious capital allocation.<br />
              <br />
              Invest in solutions that can earn 5-7% while catalysing positive impact on our planet.
            </div>
            <Button className={'z-10 '} text={<div className={'flex flex-row  items-center justify-center gap-2'}>
              <div className='text-center text-[16px] font-medium '>Our mission</div>
              <Arrow />
            </div>} onClick={
              () => {
                if (window.location.href.includes('results')) {
                  ReactGA.event({
                    category: 'RP_BTN_VIDEO_MISSION',
                    action: 'RP_BTN_VIDEO_MISSION',
                    label: 'RP_BTN_VIDEO_MISSION',
                  })
                } else {
                  ReactGA.event({
                    category: 'HP_BTN_VIDEO_MISSION',
                    action: 'HP_BTN_VIDEO_MISSION',
                    label: 'HP_BTN_VIDEO_MISSION',
                  })
                }

                window.open('/mission', '_self')
              }
            } disabled={false} type={'M'} />
          </div>
        )}
        <animated.div className='relative h-full min-h-[600px] flex flex-grow rounded-r-lg overflow-hidden items-center'
                      style={videoStyle}>
          <video
            id='responsiveVideoVertical'
            className='w-full h-full min-h-full rounded-lg '
            onCanPlay={() => console.log('Video is ready to play')}
            onError={(e) => console.error('Video error:', e)}
            playsInline
            controls={isPlaying}
            onPlay={() => setIsPlaying(true)}
          >
            <source src={'https://pyrpose.s3.eu-central-1.amazonaws.com/kevin_horizontal_1107.MP4'} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          {!isPlaying &&
            <div className='absolute inset-0 flex items-center top-0 bottom-0 justify-center'>
              <div className=' absolute bottom-4 flex items-start justify-start left-4 flex-col z-[10] w-full  '>
                <div className='text-start text-white text-[17px] font-semibold font-Inter leading-normal'>Kevin
                  Kyer
                </div>
                <div className=' text-white text-[15px] font-normal font-Inter leading-tight'>CEO, Founder of
                  Pyrpose
                </div>
              </div>
              <img
                src={KevinVertical}
                alt='Kevin'
                className='w-full h-full min-h-full object-cover  rounded-lg'
              />
            </div>
          }
        </animated.div>
        {/* {!isExpanded &&
          <div className={'absolute  w-full h-full flex items-center justify-center'}>
            <button
              className=' text-white rounded-lg p-2'
              onClick={handlePlayClick}
            >
              <svg width='112' height='112' viewBox='0 0 112 112' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect width='112' height='112' rx='56' fill='white' />
                <path d='M68 56L50 66.3923L50 45.6077L68 56Z' fill='#37992E' />
              </svg>
            </button>
          </div>
        } */}
      </div>
    </div>
  )
}

export default Video