import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoWhite from '../../assets/svg/logo-green.svg'
import { InvestmentBlock, InvestmentStepper } from './components'
import Pipes from '../Profile/shared/assets/Pipes.png'
import StepperMenu from './components/StepperMenu'
import { getInitials } from '../Profile/PrivateProfile/components/HeaderBlock'
import { useAuth } from '../../utils/AuthContext/AuthProvider'
import Qanta from './assets/Qanta.webp'
import Cookies from 'js-cookie'
import { AuthService, sendTelegramMessage } from '../../utils/api'

export enum InvestmentStep {
  InvestmentDocuments = 'Investment documents',
  // StartInvestment = 'Start investment',
  DocumentsValidation = 'Documents validation',
  TransferOfFunds = 'Transfer of funds',
  // FundsReceived = 'Funds received',
  // InvestmentCompleted = 'Investment completed'
}

const Investment = () => {
  const navigate = useNavigate()
  const steps = Object.values(InvestmentStep)
  const [showMenuBlock, setShowMenuBlock] = useState(false)
  const [clickCount, setClickCount] = useState(0);
  const [messageSent, setMessageSent] = useState(false);
  const handleFiveClicks = () => {
    setClickCount(prevCount => prevCount + 1);
    if (clickCount + 1 === 5) {
      setInvestmentStep(InvestmentStep.TransferOfFunds);
      setClickCount(0); // Сбрасываем счетчик после пяти кликов
    }
  };

  const { signOut, user } = useAuth()

  if(!user) {
    navigate('/signup')
  }
  const handleMenuIconClick = () => {
    setShowMenuBlock(!showMenuBlock)
  }

  const onLogOut = () => {
    signOut()
    localStorage.removeItem('token')
    localStorage.removeItem('InvestmentStep')
    navigate('/login')
  }
  const localStorageStep = localStorage.getItem('InvestmentStep')
  const [investmentStep, setInvestmentStep] = useState(user?.investmentStep ?? localStorageStep ?? InvestmentStep.InvestmentDocuments)
  const [amount, setAmount] = useState('0')
  const [term, setTerm] = useState('0')
  const [returnRate, setReturnRate] = useState('0')

  useEffect(() => {
    setInvestmentStep(user?.investmentStep ?? InvestmentStep.InvestmentDocuments)
  }, [user?.investmentStep])

  useEffect(() => {
    const fetchedAmount = Cookies.get('deal_amount') || '5000'
    const fetchedTerm = Cookies.get('deal_term') || '24'
    const fetchedReturnRate = Cookies.get('deal_return') || '6.00'

    setAmount(fetchedAmount)
    setTerm(fetchedTerm)
    setReturnRate(fetchedReturnRate)
  }, [])

  const updateInvestment = async(step: string) => {
    await AuthService.updateInvestmentStep({
      email: user?.email ?? '',
      investmentStep: step,
    })
    setInvestmentStep(step)
  }

  useEffect(() => {
    if (!messageSent && user && amount !== '0' && term !== '0' && returnRate !== '0') {
      sendTelegramMessage({ text: `${user.name}(${user.email}) registered with intention to invest СHF ${amount} @ ${term} ${returnRate}%` }).then(r => {
        setMessageSent(true); // Set flag to true after sending the message
      });
    }
  }, [messageSent, user, amount, term, returnRate])

  return (<div className=' flex gap-6 w-full max-w-[100vw] pb-[100px] bg-white justify-center'>
    <div className={'flex-col justify-start items-start px-4 w-full  flex max-w-[1200px]'}>
      <div className={'flex flex-row items-center justify-between w-full'}>
        <div onClick={() => {
          window.location.href = 'https://projects.pyrpose.io/qanta'
        }}
             className={' text-[15px] pb-5 cursor-pointer items-center justify-start max-sm:hidden  flex flex-row gap-2 leading-[20px] text-start text-[#737373] font-Inter'}>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.6665 7.99999H3.33317M3.33317 7.99999L7.99984 3.33333M3.33317 7.99999L7.99984 12.6667'
                  stroke='#737373' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
          Back
        </div>
        <img src={LogoWhite} alt='logo' onClick={() => {
          navigate('/')
        }} className={'pt-[20px]'} />
        <div className='relative' onMouseEnter={handleMenuIconClick} onMouseLeave={handleMenuIconClick}>
          <div
            className={'w-10 h-10 bg-green-200 hover:bg-[#83F279] cursor-pointer rounded-[40px] flex items-center justify-center'}
          >
            {showMenuBlock ? (
              <div className='w-11 h-11 left-[-2px] top-[-2px] absolute rounded-[60px] border border-green-200' />
            ) : (
              <div />
            )}
            <div className="text-center text-green-800 text-[17px] font-medium font-['Inter'] leading-normal">
              {getInitials(user?.name || '')}
            </div>
          </div>
          {showMenuBlock && (
            <div className='absolute right-0 mt-0 w-48 pt-2  z-10'>
              <div className={'w-48 bg-white border-[2px] border-green-200 rounded-md shadow-lg'}>
              <div
                className='block font-Inter font-semibold px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100'
                onClick={onLogOut}
              >
                Log Out
              </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <InvestmentBlock
        name='Qanta Energy'
        amount={`${amount} CHF`}
        term={`${term} years`}
        returnRate={`${returnRate}%`}
        imgSrc={Qanta}
      />
      <div className={'flex flex-row gap-6 w-full  pt-6'}>
        <div className={'w-[40%] flex flex-col gap-6'}>
          <InvestmentStepper currentStep={investmentStep} steps={steps} />

          <div
            className='bg-[#F2F2EF] flex flex-row items-center justify-between px-8 py-6 rounded-lg shadow-md w-full '>
            <div className={'text-[#525252] text-[15px] leading-[18px]'}>
              Investment status
            </div>
            <div
              onClick={handleFiveClicks}
              className={'px-3 py-2 border-[1px] font-medium border-[#737373] text-black rounded-full text-[15px] leading-[18px]'}>
              UNCONFIRMED
            </div>
          </div>
        </div>
        <div className={'w-[60%] flex flex-col gap-6'}>
          <StepperMenu   amount={amount}
                         term={term}
                         returnRate={returnRate} currentStep={investmentStep} setStep={updateInvestment} />
        </div>
      </div>
    </div>
  </div>)
}

export default Investment