import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getAllTransactions } from '../../../utils/api'
import LoaderPulse from '../../../components/LoaderPulse'
import {
  HeaderBlock,
  ImpactBlock,
  MainBlock,
  MainChart,
  NewsBlock,
  ProfileFooter,
  ProjectsList,
  SuccessModal,
} from './components'
import { ContainerCard } from '../shared/helpers'
import { useAuth } from '../../../utils/AuthContext/AuthProvider'
import Footer from '../../LandingV4/components/Footer'
import { Footer as MobileFooter } from '../../LandingV3/components'

interface ProfileProps {
  name?: string
  email?: string
  id?: string
  lastTransaction?: string
}

const ProfileScreen: React.FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get('email')
  const { token: userToken, user } = useAuth()
  const token = userToken ?? queryParams.get('token') ?? localStorage.getItem('token') ?? ''
  const navigate = useNavigate()
  const paymentSuccess = localStorage.getItem('successPayment') === 'true'
  const [success, setSuccess] = useState<boolean>(paymentSuccess)

  if (!user) {
    navigate('/login')
  }

  const { data, isLoading } = useQuery(['profile'], () => getAllTransactions(email || '', {
    headers: { Authorization: `Bearer ${token}` },
  }), {
    enabled: !!user,
  })

  // useEffect(() => {
  //     if (!isLoading && data?.ownTransactions?.length === 0) {
  //       navigate('/checkout', {
  //         state: { amount: parseFloat(user?.lastTransaction || '49') ?? 49 },
  //       })
  //     }
  //   }
  //   ,
  //   [isLoading, data?.ownTransactions || data?.referenceTransactions],
  // )

  if (isLoading) {
    return <LoaderPulse />
  }

  // const Wrapper = window.window.innerWidth > 640 ? 'div' : ContainerCard

  return (
    <div className={'w-full h-full flex-col bg-[#F2F2EF] min-h-screen flex items-center justify-center relative'}>
      {
        success && (
          <SuccessModal closeModal={
            () => {
              localStorage.setItem('successPayment', 'false')
              setSuccess(false)
            }
          } />
        )
      }
      <HeaderBlock name={user?.name} email={user?.email} />
      {/* <div */}
      {/*   className=' w-full bg-center bg-cover flex flex-col justify-between px-4 items-center text-white text-4xl ' */}
      {/*   style={{ */}
      {/*     backgroundImage: `url(${HeroFooterBackground})`, */}
      {/*     backgroundRepeat: 'no-repeat', */}
      {/*     backgroundSize: 'cover', */}
      {/*     backgroundColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 21.35%, rgba(0, 0, 0, 0) 100%)', */}
      {/*   }}> */}
      {/*   <div */}
      {/*     className='w-full pt-[66px] pb-[24px] sm:max-w-[608px] sm:mx-auto text-white text-start text-[34px] sm:text-[48px] font-medium leading-[38px]' */}
      {/*     style={{ */}
      {/*       fontFamily: 'IBM Plex Serif', */}
      {/*     }} */}
      {/*   >My Contribution */}
      {/*   </div> */}
      {/* </div> */}
      <div className={'flex flex-col w-full '}>
        {/* <MainBlock */}
        {/*   totalCo2={data?.totalCo2Reduced} */}
        {/*   ownContribution={data?.ownContribution} */}
        {/*   referenceContribution={data?.referenceContribution} */}
        {/*   totalContribution={data?.totalContribution} */}
        {/*   profileId={user?.id || ''} */}
        {/* /> */}
        {/* <Wrapper> */}
        {/*   <div className={'sm:hidden'}> */}
        {/*     <PayBlock /> */}
        {/*   </div> */}
        {/*   <div className={'hidden sm:block'}> */}
        {/*     <PayBlockDesktop profileBlock={true} privateProfile={true} /> */}
        {/*   </div> */}
        {/* </Wrapper> */}
        <MainChart/>
        <ImpactBlock name={user?.name} profileId={user?.id || ''} totalCo2={data?.totalCo2Reduced}  />
        <ProjectsList />
        <NewsBlock />
        {/* <ContainerCard> */}
        {/*   <HistoryTransactionsBlock ownTransactions={data?.ownTransactions ?? []} */}
        {/*                             referenceTransactions={data?.referenceTransactions ?? []} /> */}
        {/* </ContainerCard> */}
        {/* <a href={'mailto:support@pyrpose.io'} */}
        {/*    className='w-full h-12 px-6 py-3.5 rounded-xl border border-stone-950 justify-center max-w-[640px] items-center gap-2 inline-flex'> */}
        {/*   <div className="text-center text-stone-950 text-[17px] font-medium font-['Inter'] leading-tight">Any */}
        {/*     questions? */}
        {/*     Contact us */}
        {/*   </div> */}
        {/* </a> */}
        {/* <div className={'flex flex-col gap-4 items-start justify-between max-w-[640px]'}> */}
        {/*   <a href={'mailto:support@pyrpose.io'} */}
        {/*      className="text-neutral-600 text-[17px] font-normal font-['Inter'] leading-normal">support@pyrpose.io</a> */}
        {/*   <div className={'flex flex-row items-center gap-2'}> */}
        {/*     <a href='https://www.linkedin.com/company/pyrpose/' target={'_blank'} rel='noreferrer'> */}
        {/*       <LinkedInIcon /> */}
        {/*     </a> */}
        {/*     <a href='https://www.instagram.com/pyrpose.io/' target={'_blank'} rel='noreferrer'> */}
        {/*       <InstagramIcon /> */}
        {/*     </a> */}
        {/*     <a href={'https://www.youtube.com/@Pyrpose'} target={'_blank'} rel='noreferrer'> */}
        {/*       <YouTubeIcon /> */}
        {/*     </a> */}
        {/*   </div> */}
        {/* </div> */}

      </div>
      {/* <ProfileFooter /> */}
      <Footer />
      <div className={'md:hidden'}>
        <MobileFooter />
      </div>
    </div>
  )
}

export default ProfileScreen
