import React, { useEffect } from 'react'
import Header from '../LandingV4/components/Header'
import OurMissionHeader from './assets/images/our-mission-header.webp'
import LogoIcon from './assets/images/LogoIcon.svg'
import Circle from './assets/images/circle.svg'
import TeamSectionList from './components/TeamSectionList'
import Footer from '../LandingV3/components/Footer'


const OurMission = () => {
  useEffect(() => {
    const hash = window.location.hash
    if (hash && hash === '#team') {
      const teamSection = document.getElementById('team')
      if (teamSection) {
        // Scroll to the 'team' section
        window.scrollTo({
          top: teamSection.offsetTop + 1,
          behavior: 'smooth',
        })
      }
    }
  }, [])
  return (
    <div className={'w-full min-w-full flex-col h-full text-start bg-white flex-1 flex '}>
      <div data-blockname='Header'><Header /></div>
      <div className={'w-full relative sm:max-h-[700px]  '}>
        <div className='relative w-full md:h-[700px] max-sm:h-[500px]' style={{ paddingTop: '53%' }}>
          <img
            src={OurMissionHeader}
            alt='projects-background'
            className={'absolute top-0 left-0 w-full md:max-h-[700px] h-full object-cover md:object-fill'}
          />
        </div>
        <div
          className={'absolute top-0 left-0 flex-col sm:gap-[64px] gap-[40px] right-0 bottom-0 flex items-center justify-center '}>
          <div
            className=' opacity-90 text-center sm:pt-[20px] max-w-[900px] text-green-100 px-[40px] sm:text-[100px] text-[64px] font-medium font-IBM leading-[64px] sm:leading-[70px]'>Our
            Mission
          </div>
          <img src={LogoIcon} alt='logo' className={'w-[74px] sm:w-[120px] sm:h-[170px] h-[102px] '} />
        </div>
      </div>
      <div className='w-full bg-white justify-center items-start gap-2.5 inline-flex'>
        <div className='sm:max-w-[640px] px-4 pt-6 pb-20 bg-white flex-col justify-start items-start gap-8 inline-flex'>
          <div className='self-stretch flex-col justify-start items-start gap-2 flex'>
            <div
              className='self-stretch text-green-600 text-xs font-bold font-Inter uppercase leading-tight tracking-wide'>make
              an impact
            </div>
            <div className='self-stretch text-stone-950 text-[28px] font-semibold font-IBM leading-9'>We
              believe that everyone should have an opportunity to contribute to the global shift towards sustainable
              planet
            </div>
          </div>
          <div
            className='self-stretch text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>Currently,
            73% of greenhouse gas emissions stem from the energy we use and create. We recognize the urgency to
            transition away from fossil fuels and embrace cleaner alternatives.
          </div>
          <div className='self-stretch pb-6 bg-white flex-col justify-start items-center gap-10 flex'>
            <div className='self-stretch pb-3 border-b border-green-600 justify-start items-start gap-2.5 inline-flex'>
              <div className='grow shrink basis-0 text-stone-950 text-xl font-semibold font-Inter leading-7'>Global
                greenhouse gas emissions by sector
              </div>
            </div>
            <div className='self-stretch flex-col justify-start items-center flex'>
              <img src={Circle} alt='circle' />
              <div className='self-stretch pt-3 justify-between relative items-end gap-2.5 inline-flex'>
                <div className=''><span
                  className='text-neutral-600 text-[13px] font-normal font-Inter leading-tight'>Source: </span><a
                  href={'https://ourworldindata.org/emissions-by-sector'}
                  className='text-neutral-600 text-[13px] font-normal font-Inter underline leading-tight'>OurWorldinData.org</a>
                </div>
                <div className='w-[51px] h-[34px] relative'>
                  <div className='w-[51px] h-[31.17px] left-0 top-0 absolute bg-sky-950' />
                  <div className='w-[51px] h-[2.83px] left-0 top-[31.17px] absolute bg-red-600' />
                  <div
                    className="left-[3.30px] top-[6.61px] absolute text-center text-white text-[9.44px] font-semibold font-['Lato']">Our
                    World<br />in Data
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='self-stretch text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>We already have the solutions to transition away from fossil fuels. Solar energy is the easiest to understand the fastest to deploy.  However, any solution that has a clear line of reduction in carbon emissions can go on our platform so long as it is validated to reduce future carbon emissions.
            <br /><br />

            Today, the energy transition is supported by governments, corporations, climate venture capitalists, and high-net-worth individuals. They not only contribute to the planet&apos;s well-being but also generate financial returns. Yet, the climate-conscious consumer, without millions in their bank account, often finds it challenging to be part of this transformative movement. This is where Pyrpose steps in.
            <br /><br />

            We believe it is both within our power and responsibility to invest in the future of the planet. Pyrpose is dedicated to creating a platform where every individual, regardless of their financial capacity, can play a role in shaping a more sustainable world.
          </div>
        </div>
      </div>
      <div id={'team'}>
        <TeamSectionList />
      </div>
      <Footer />
    </div>
  )
}

export default OurMission
