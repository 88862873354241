import React from 'react'
import TeamCard from '../TeamCard'
import Kevin from '../../assets/images/team/Kevin.webp'
import Christoph from '../../assets/images/team/Christoph.webp'
import Marija from '../../assets/images/team/Marija.webp'
import Victoria from '../../assets/images/team/Victoria.webp'
import Fleur from '../../assets/images/team/Fleur.webp'
import Florian from '../../assets/images/team/Florian.webp'
import Ed from '../../assets/images/team/Ed.webp'
import Vivek from '../../assets/images/team/Vivek.webp'
import Glisten from '../../assets/images/team/Glisten.webp'
import Rishi from '../../assets/images/team/Rishi.webp'
import Anthony from '../../assets/images/team/Anthony.webp'

const teamData: teamDataI[] = [
  {
    title: 'Team',
    members: [
      {
        name: 'Kevin Kyer',
        photo: Kevin,
        role: 'CEO, Co-Founder',
        description: 'Kevin, our visionary force behind Pyrpose, is reshaping the narrative of impact and climate finance.',
        url: 'https://www.linkedin.com/in/kevinmkyer/',
      },
      {
        name: 'Christoph Maichel',
        photo: Christoph,
        role: 'CTO, Co-Founder',
        description: 'From Advisor to CTO. He loves to program himself, but knows to lead the team to make work and the world better.',
        url: 'https://www.linkedin.com/in/christophmaichel/',
      },  {
        name: 'Fleur Heyns',
        photo: Fleur,
        role: 'Chairwoman',
        description: 'Co-Founder & CEO of Proof. An experienced serial entrepreneur who since her first exit in 2007 has been exclusively dedicated to the scaling of impact investing and adoption of ESG and Impact data into mainstream financial products and markets.',
        url: 'https://www.linkedin.com/in/fleur-heyns-9a8a9436/',
      },{
        name: 'Marija Poposka',
        photo: Marija,
        role: 'Marketing Manager',
        description: 'Marija is the creative fairy of our team, crafting campaigns and messaging, that resonate with precision.',
        url: 'https://www.linkedin.com/in/marijapoposka/',
      }, 
       {
        name: 'Vivek Chandaria',
        photo: Vivek,
        role: 'Director',
        description: 'Entrepreneur and advisor to various Private Equity Funds and Investments of the Family. Former CEO of Metecno Group, a Global Supplier to the Construction Industry. CEO Council of an Indian Private Equity Fund.',
        url: 'https://www.linkedin.com/in/vivek-chandaria-40a2392/',
      }, {
        name: 'Florian Kemmerich',
        photo: Florian,
        role: 'Advisor',
        description: 'Florian is entrepreneur, board member & impact investor.  Managing Partner at Bamboo Capital Partners, formerly Partner at PARTER Capital, Co-Founder of Histide, and C-Suite executive at SBi (Stryker), Olympus and B.Braun.',
        url: 'https://www.linkedin.com/in/floriankemmerich/',
      }, {
        name: 'Ed Stevens',
        photo: Ed,
        role: 'Advisor',
        description: 'As Founder and CEO of Scoot, a certified B Corporation. Chairman of DemandQ, chapter vice chair of YPO Santa Barbara. In 2000 he founded Shopatron a world leader of cloud based e-commerce order management systems.',
        url: 'https://www.linkedin.com/in/edwardstevens/',
      }, {
        name: 'Glisten Cieslak',
        photo: Glisten,
        role: 'Advisor',
        description: 'Marketer, advisor, and entrepreneur. Glisten founded and runs Assembly 7 — a growth and marketing consultancy. Former executive at Omnicom, Siegel+Gale, and UTA (United Talent Agency).',
        url: 'https://www.linkedin.com/in/glistencieslak/',
      }, {
        name: 'Rishi Bhattacharya',
        photo: Rishi,
        role: 'Advisor',
        description: 'Rishi is the Chief Executive Officer and Founder of Impact & Influence. He has 25 years of communications experience, as both an advisor and a journalist.',
        url: 'https://www.linkedin.com/in/rishi-bhattacharya-7ab49b1b/',
      }, {
        name: 'Anthony Stephan',
        photo: Anthony,
        role: 'Advisor',
        description: 'Anthony Stephan is a prominent sustainable real estate developer and impact investor in innovative companies, including Pyrpose, BBOXX, Goodwall, Proof, Minexx, and Impact District. Anthony holds board positions in organizations like YPO and UNGC.',
        url: 'https://www.linkedin.com/in/-anthony-stephan/',
      }],
  },

]

interface teamDataI {
  title: string,
  members: teamMember[]
}

interface teamMember {
  name: string
  photo: string
  role?: string
  description?: string
  url: string
}

const TeamSectionList = () => {
  return (
    <div className='w-full bg-[#E3E3DD] py-[80px]'>
      <div className={'sm:max-w-[640px] sm:mx-auto flex flex-col text-start  px-4 w-full'}>
        <div className='flex-col justify-start items-start gap-2 inline-flex'>
          <div
            className='text-start text-green-600 text-xs font-bold font-Inter uppercase leading-tight tracking-wide'>People
            with Pyrpose
          </div>
          <div className='text-start text-stone-950 text-[28px] font-semibold font-IBM '>Introducing Our Team</div>
        </div>
        <div
          className='w-full text-start py-8 text-neutral-600 text-[17px] font-normal font-Inter leading-normal'>Discover
          our team of individuals driving positive change — professionals with backgrounds from top-tier investment and
          technology companies.
        </div>
        {teamData.map((section) => (
          <div key={section.title} className='mb-8'>
            <div
              className='w-full h-10 mb-8 pb-3 border-b border-green-600 justify-start items-start gap-2.5 inline-flex'>
              <div className='text-start text-stone-950 text-xl font-semibold font-Inter '>{section.title}</div>
            </div>
            <div className='flex flex-wrap justify-between flex-col sm:flex-row gap-y-8'>
              {section.members.map((member: teamMember) => (
                <TeamCard
                  key={member.name}
                  image={member.photo}
                  name={member.name}
                  role={member?.role || ''}
                  description={member?.description}
                  url={member.url}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TeamSectionList
