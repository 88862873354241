import React, { useState } from 'react'
import LogoWhite from '../../../../../assets/svg/logo-green.svg'
import { useNavigate } from 'react-router-dom'
import ArrowIcon from '../../../shared/icons/ArrowIcon'
import LogoIcon from '../../../shared/icons/LogoIcon'
import { useAuth } from '../../../../../utils/AuthContext/AuthProvider'

interface HeaderBlockProps {
  name?: string
  email?: string
}

export const getInitials = (name: string): string => {
  if (!name.trim()) {
    return 'US'
  }
  const parts = name.split(' ')
  if (parts.length === 1) {
    return parts[0].substring(0, 2).toUpperCase()
  } else {
    return (parts[0][0] + parts[1][0]).toUpperCase()
  }
}

const HeaderBlock: React.FC<HeaderBlockProps> = ({
  name,
  email,
}) => {
  const [showMenuBlock, setShowMenuBlock] = useState(false)
  const navigate = useNavigate()
  const { signOut } = useAuth()
  const handleMenuIconClick = () => {
    setShowMenuBlock(!showMenuBlock)
  }

  const onLogOut = () => {
    signOut()
    localStorage.removeItem('token')
    navigate('/login')
  }

  const onMyClimateAchievements = () => {
    navigate('/profile-achievements')
  }

  const onProfileClick = () => {
    navigate('/profile')
  }

  const href = window.location.pathname
  const isProfile = href === '/profile'

  return (
    <>
      <div
        className='w-full h-[65px] px-4 py-3 bg-white shadow backdrop-blur-[100px] flex-col justify-start items-center gap-2 inline-flex'>
        <div className=' w-full h-full sm:px-[90px] justify-between items-center inline-flex'>
          <img src={LogoWhite} alt='logo' onClick={() => {
            navigate('/')
          }} className={'pt-[20px]'} />
          <div className='relative inline-flex flex-row items-center gap-4 '>
            <a href={'/referral'}
               className={'text-[17px] leading-[20px] font-Inter mr-4'} rel='noreferrer'>
              Referral
            </a>
            <a href={'https://projects.pyrpose.io'} target={'_blank'}
               className={'text-[17px] leading-[20px] font-Inter mr-4'} rel='noreferrer'>
              Projects
            </a>
            <div
              className={'w-10 h-10  bg-green-200 hover:bg-[#83F279] cursor-pointer rounded-[40px] flex items-center justify-center'}
              onClick={handleMenuIconClick}>
              {showMenuBlock ?
                <div className='w-11 h-11 left-[-2px] top-[-2px] absolute rounded-[60px] border border-green-200' /> :
                <div />}
              <div
                className=" text-center text-green-800 text-[17px] font-medium font-['Inter'] leading-normal">{getInitials(name || '')}</div>
            </div>
            {
              showMenuBlock && (
                <div
                  className='w-[221px] max-sm:hidden p-2 bg-white rounded-lg shadow justify-start items-start gap-2 inline-flex absolute top-full right-0 mt-1 z-[1000] '>
                  <div className='flex-col justify-start items-start inline-flex w-full'>
                    {!isProfile && (
                      <div
                        className='px-3 py-2 hover:bg-black hover:bg-opacity-10 w-full cursor-pointer rounded justify-start items-center inline-flex'
                        onClick={onProfileClick}>
                        <div
                          className="text-stone-950 text-[15px] text-start font-normal font-['Inter'] leading-normal">My
                          Profile
                        </div>
                      </div>
                    )
                    }
                    <div
                      className='px-3 py-2 hover:bg-black hover:bg-opacity-10 w-full cursor-pointer rounded justify-start items-center gap-2.5 inline-flex'
                      onClick={onLogOut}>
                      <div className="text-stone-950 text-[15px] font-normal font-['Inter'] leading-normal">Log out</div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {
        showMenuBlock && (
          <div className={'bg-[#E3E3DD] absolute top-0 bottom-0 left-0 right-0 z-[100]  w-[100vw] h-[100vh]'}>
            <div
              className='w-full px-4 py-3 bg-black backdrop-blur-[100px] flex-row justify-between items-center gap-2 inline-flex'>
              <div className=' justify-start items-center gap-1.5 w-1/3 flex-row inline-flex cursor-pointer'
                   onClick={() => {
                     setShowMenuBlock(false)
                   }}>
                <ArrowIcon className={'rotate-180'} stroke={'#FFFFFF'} />
                <div className="text-white text-[17px] font-normal font-['Inter'] leading-normal">Back</div>
              </div>
              <div className="w-1/3 text-center text-white text-xl font-semibold font-['Inter'] leading-normal">My Profile
              </div>
              <div className={'w-1/3 flex items-center justify-end'}>
                <LogoIcon onClick={() => {
                  navigate('/')
                }} />
              </div>
            </div>
            <div className={'flex flex-col px-4 py-8 items-center '}>
              <div className={'flex flex-row items-center w-full justify-start gap-4'}>
                <div
                  className={'w-12 h-12 bg-[#20591B] cursor-pointer rounded-[40px] flex items-center justify-center'}
                >
                  <div
                    className=" text-center text-[#D3F3D1] text-[17px] font-medium font-['Inter'] leading-normal">{getInitials(name || '')}</div>
                </div>
                <div className={'flex flex-col justify-start text-start '}>
                  <div className="text-zinc-900 text-xl font-semibold font-['Inter'] leading-normal">{name}</div>
                  <div className="text-neutral-600 text-[15px] font-normal font-['Inter'] leading-tight">{email}</div>
                </div>
              </div>
              <div
                className='w-full p-2 mt-6 bg-white rounded-lg shadow justify-start items-start gap-2 inline-flex  '>
                <div className='flex-col justify-start items-start inline-flex w-full'>
                  {!isProfile && (
                    <div
                      className='px-3 py-2 max-sm:py-4 border-b-[1px] border-[#00000014] cursor-pointer w-full justify-start items-center inline-flex'
                      onClick={onProfileClick}>
                      <div
                        className="text-stone-950 text-[15px] text-start font-normal font-['Inter'] leading-normal">My
                        Profile
                      </div>
                    </div>
                  )
                  }
                  <div
                    className='px-3 py-2 max-sm:py-4 cursor-pointer w-full rounded justify-start items-center gap-2.5 inline-flex'
                    onClick={onLogOut}>
                    <div className="text-stone-950 text-[15px] font-normal font-['Inter'] leading-normal">Log out</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }
    </>
  )
}

export default HeaderBlock