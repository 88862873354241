import React, { useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import Arrow from '../../../LandingV3/icons/Arrow'
import BuildingTransformation from '../../../LandingV3/assets/images/BuildingTransformation.webp'
import SolarArrays from '../../../LandingV3/assets/images/SolarArrays.webp'
import WindFarm from '../../../LandingV3/assets/images/WindFarm.webp'
import Button from '../../../LandingV3/components/Button'

interface ProjectCardProps {
  imgSrc: string;
  location: string;
  title: string;
  description: string;
  GaEvent: string;
  url?: string;
  GaEvent2: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ imgSrc, location, title, description, GaEvent, GaEvent2, url }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}
         onClick={
           () => {
             ReactGA.event({
               category: GaEvent,
               action: GaEvent,
               label: GaEvent,
             })
             if (window.location.href.includes('results')) {
               ReactGA.event({
                 category: 'RP_MENU_PROJECTS',
                 action: 'RP_MENU_PROJECTS',
                 label: 'RP_MENU_PROJECTS',
               })
             } else {
               ReactGA.event({
                 category: 'HP_MENU_PROJECTS',
                 action: 'HP_MENU_PROJECTS',
                 label: 'HP_MENU_PROJECTS',
               })
             }

             window.open(url, '_self')
           }
         }
         className={`hover:shadow-xl max-sm:!shadow-sm cursor-pointer bg-white transition duration-500 rounded-xl mb-4 flex-col justify-start sm:flex-1 max-sm:w-[95%] items-start inline-flex flex-shrink-0 hover:border-[1px] hover:border-[#fff] border-[1px] border-[#E5E5DF] ${isHovered ? 'translate-y-[-8px]' : ''} `}>
      <div className='w-full !h-[280px] min-h-[280px] relative'>
        <img
          className={`w-full h-full absolute top-0 object-cover transition duration-700 rounded-t-[12px] ${!isHovered ? 'sm:grayscale' : ''}  `}
          src={imgSrc} alt={title} />
        <div
          className='px-[12px] py-[6px] left-[12px] top-[236px] absolute bg-white bg-opacity-75 rounded-[200px] justify-start items-center gap-1 inline-flex'>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd'
                  d='M2 6.66669C2 3.35298 4.68629 0.666687 8 0.666687C11.3137 0.666687 14 3.35298 14 6.66669C14 8.35081 13.2354 9.82684 12.1949 11.178C11.3253 12.3071 10.2122 13.4106 9.10043 14.5127L9.09965 14.5135C8.88964 14.7217 8.67968 14.9298 8.4714 15.1381C8.21106 15.3984 7.78895 15.3984 7.5286 15.1381C7.32018 14.9297 7.1096 14.7209 6.89944 14.5126C5.78764 13.4104 4.6747 12.3071 3.80515 11.178C2.76455 9.82684 2 8.35081 2 6.66669ZM10 6.66669C10 7.77126 9.10457 8.66669 8 8.66669C6.89543 8.66669 6 7.77126 6 6.66669C6 5.56212 6.89543 4.66669 8 4.66669C9.10457 4.66669 10 5.56212 10 6.66669Z'
                  fill='#525252' />
          </svg>
          <div
            className='text-center text-stone-950 text-[15px] font-medium font-Inter '>{location}</div>
        </div>
      </div>
      <div className='self-stretch px-4 pt-5 pb-6 flex-col justify-between h-full items-start gap-4 flex'>
        <div className='self-stretch flex-col justify-start items-start gap-1 flex'>
          <div
            className=' text-stone-950 text-[20px] font-semibold font-Inter text-start'>{title}</div>
          <div
            className=' text-[#525252] text-[17px] font-normal font-Inter text-start'>{description}</div>
        </div>
        <a
            href={url}
            target={'_self'}

           className='px-3 py-1.5 bg-[#37992E] rounded-md cursor-pointer hover:bg-[#2E8026] justify-start items-center gap-1.5 inline-flex'
           onClick={() => {
             ReactGA.event({
               category: GaEvent2,
               action: GaEvent2,
               label: GaEvent2,
             })
             if (window.location.href.includes('results')) {
               ReactGA.event({
                 category: 'RP_MENU_PROJECTS',
                 action: 'RP_MENU_PROJECTS',
                 label: 'RP_MENU_PROJECTS',
               })
             } else {
               ReactGA.event({
                 category: 'HP_MENU_PROJECTS',
                 action: 'HP_MENU_PROJECTS',
                 label: 'HP_MENU_PROJECTS',
               })
             }
           }}>
          <div className='text-white text-[17px] cursor-pointer font-medium font-Inter '>Learn more</div>
          <Arrow stroke={'#fff'} />
        </a>
      </div>
    </div>
  )
}
const Projects: React.FC = () => {
  const scrollContainerRef = useRef(null)

  return (
    <div className={'w-full flex justify-center sm:px-12 px-4 items-center bg-white'}>
      <div
        className='container py-[120px] max-sm:py-[80px] max-w-[1400px]  flex flex-col justify-start items-start gap-8 '>
        <div className='flex-col justify-start items-start gap-5 flex w-full mx-auto'>
          <div className='flex-col justify-start items-start gap-2 flex w-full max-w-[100vw]'>
            <div className={'w-full flex flex-row justify-between items-center'}>
              <div className='text-green-600 text-xs font-bold uppercase'>
                Our projects
              </div>
              <a href={'/projects'}>
                <svg width='36' height='20' viewBox='0 0 36 20' className={'sm:hidden'} fill='none'
                     xmlns='http://www.w3.org/2000/svg'>
                  <path d='M3 9.99996H32.5M32.5 9.99996L26.6667 4.16663M32.5 9.99996L26.6667 15.8333' stroke='#37992E'
                        strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </a>
            </div>
            <div
              className='w-full text-start xl:text-[42px] xl:leading-[48px] text-[32px] leading-[38px] pb-4 font-semibold '>Renewable
              energy solutions
            </div>
          </div>
          <div ref={scrollContainerRef}
               className='w-full flex flex-row sm:gap-[24px] gap-4 max-sm:pr-4 relative max-sm:overflow-x-auto max-sm:max-w-[95vw]'>
            <ProjectCard
              imgSrc={SolarArrays}
              data-projectname='Solar Arrays'
              location='Switzerland'
              title='Solar Arrays'
              GaEvent={window.location.href.includes('results') ? 'RP_PROJECT1_CLICK' : 'HP_PROJECT1_CLICK'}
              GaEvent2={window.location.href.includes('results') ? 'RP_PROJECT1_CLICK_BUTTON' : 'HP_PROJECT1_CLICK_BUTTON'}
              // url={'/projects/solar-at-homes'}
              url={'#signup'}
              description='Solar arrays are linked to the grid, reducing carbon emissions, and generating a return through power purchase agreements.'
            />
            <ProjectCard
              imgSrc={BuildingTransformation}
              data-projectname='Building Transformation'
              location='Switzerland'
              title='Building Transformation'
              GaEvent={window.location.href.includes('results') ? 'RP_PROJECT2_CLICK' : 'HP_PROJECT2_CLICK'}
              GaEvent2={window.location.href.includes('results') ? 'RP_PROJECT2_CLICK_BUTTON' : 'HP_PROJECT2_CLICK_BUTTON'}
              // url={'/projects/clean-cooking'}
              url={'#signup'}
              description='Buildings need to be future fit for clean energy transition so as to have  lower carbon footprint.'
            />
            <ProjectCard
              imgSrc={WindFarm}
              location='Switzerland'
              title='Wind Farm'
              // url={'/projects/solar-at-schools'}
              url={'#signup'}
              GaEvent={window.location.href.includes('results') ? 'RP_PROJECT3_CLICK' : 'HP_PROJECT3_CLICK'}
              GaEvent2={window.location.href.includes('results') ? 'RP_PROJECT3_CLICK_BUTTON' : 'HP_PROJECT3_CLICK_BUTTON'}
              description='Wind turbines are extremely efficient in reducing carbon emissions and can provide a substantial reduction in energy cost.'
            />
          </div>
          <Button text={
            <div className={'flex flex-row items-center gap-2'}>
              <div
                className='text-center text-stone-950 text-[17px] font-medium font-Inter leading-tight'>Discover
                all
              </div>
              <Arrow />
            </div>
          } onClick={() => {
            if (window.location.href.includes('results')) {
              ReactGA.event({
                category: 'RP_BTN_PROJECT_DISCOVER',
                action: 'RP_BTN_PROJECT_DISCOVER',
                label: 'RP_BTN_PROJECT_DISCOVER',
              })
            } else {
              ReactGA.event({
                category: 'HP_BTN_PROJECT_DISCOVER',
                action: 'HP_BTN_PROJECT_DISCOVER',
                label: 'HP_BTN_PROJECT_DISCOVER',
              })
            }
            window.open('#signup', '_self')
          }} type='M' />
        </div>
      </div>
    </div>

  )
}

export default Projects