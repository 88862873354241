import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReactGA from 'react-ga4'
import PrimaryButton from '../../../../components/PrimaryButton'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Bounce, toast } from 'react-toastify'
import { useAuth } from '../../../../utils/AuthContext/AuthProvider'
import { AuthService } from '../../../../utils/api'
import Cookies from 'js-cookie'


const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().required('Required'),
})

interface SignUpFormProps {
  handleLogin: () => void
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  handleLogin,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [amount, setAmount] = useState('0')
  const [term, setTerm] = useState('0')
  const [returnRate, setReturnRate] = useState('0')
  useEffect(() => {
    const fetchedAmount = Cookies.get('deal_amount') || 'none'
    const fetchedTerm = Cookies.get('deal_term') || 'none'
    const fetchedReturnRate = Cookies.get('deal_return') || 'none'

    setAmount(fetchedAmount)
    setTerm(fetchedTerm)
    setReturnRate(fetchedReturnRate)
  }, [])
  const { signUp, isLoading: ProfileLoading } = useAuth()
  const navigate = useNavigate()


  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      ReactGA.event({
        category: 'RP_BTN_SIGN_UP',
        action: 'RP_BTN_SIGN_UP',
        label: 'RP_BTN_SIGN_UP',
      })
      try {
        await signUp({
          deal_amount: amount,
          deal_return: returnRate,
          deal_term: term, ...values,
        })
        navigate('/investment')
        setIsLoading(false)
      } catch (error: any) {
        setIsLoading(false)
        let errorMessage = 'An error occurred during sign-up. Please try again.'
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message
        }
        toast.error(errorMessage, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        })
      }
    },
  })

  if (ProfileLoading) return <CircularProgress size={24} />

  const verifyEmail = async () => {
    const data = await AuthService.verifyEmail(formik.values.email ?? '')
    await formik.setFieldTouched('email', true)
    if (data) {
      formik.setFieldError('email', 'Email already exists')
    }
  }

  return (
    <form className={'gap-4 flex sm:max-w-[480px] w-full flex-col bg-[#F2F2EF] p-8 rounded-[12px]'}
          onSubmit={formik.handleSubmit}>
      <div className={'flex flex-row gap-4'}>
        <div className={'flex flex-col flex-start flex-1'}>
          <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
            First Name
          </div>
          <input
            id='name'
            name='name'
            type='text'
            placeholder='Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className={'text-red-500 text-[12px]  text-start'}>{formik.errors.name}</div>
          ) : null}
        </div>
        <div className={' flex flex-col flex-start  flex-1'}>
          <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
            Last Name
          </div>
          <input
            id='lastName'
            name='lastName'
            type='text'
            placeholder='Last Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.lastName}</div>
          ) : null}
        </div>
      </div>
      <div>
        <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
          Email
        </div>
        <input
          id='email'
          name='email'
          type='email'
          placeholder='Email'
          onChange={formik.handleChange}
          onBlur={verifyEmail}
          value={formik.values.email}
          className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.email}</div>
        ) : null}
      </div>
      <div>
        <div className={'font-Inter text-start pb-2 font-[500] text-[14px]'}>
          Password
        </div>
        <input
          id='password'
          name='password'
          type='password'
          placeholder='Password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className={' rounded-[8px] !border-[1px] !h-[44px] !border-[#CCCCCC] focus:!outline-[#000] focus:!border-[1px] px-[10px] py-[14px] w-full'}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className={'text-red-500 text-[12px] text-start'}>{formik.errors.password}</div>
        ) : null}
      </div>
      <span className={' text-[15px] text-start leading-[20px] text-[#737373] font-Inter'}>
          By creating account, you agree to Pyrpose`s <span onClick={() => {
        navigate('/terms-of-use', { replace: false })
      }}
                                                            className={'underline cursor-pointer underline-offset-2 text-[#525252]'}>Terms of Use</span> and <span
        onClick={() => {
          navigate('/privacy-policy', { replace: false })
        }
        }
        className={'underline cursor-pointer underline-offset-2 text-[#525252]'}>Privacy Policy</span>
        </span>
      <PrimaryButton
        onClick={() => formik.handleSubmit()}
        disabled={isLoading}
        className=' !bg-[#000] !w-full h-[48px] !my-[16px] rounded-xl justify-center items-center gap-2 inline-flex'
      >
        {formik.isSubmitting ? (<CircularProgress size={24} />) :
          <div className='text-center text-white !text-[17px] font-semibold !font-Inter normal-case '>Sign Up</div>
        }
      </PrimaryButton>
      <span className={' text-[15px] leading-[20px] text-start text-[#737373] font-Inter'}>
          Already have an account?  <a href={'/login'}
                                       className={'underline cursor-pointer underline-offset-2 text-start text-[#525252]'}>Log in instead</a>
        </span>
    </form>
  )
}

export default SignUpForm