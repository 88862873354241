import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AuthService, getAllTransactions } from '../api'

// Use the interfaces defined above
interface AuthProviderProps {
  children: ReactNode;
}

export interface User {
  email: string;
  id: string;
  name: string;
  TypeAlias: string;
  lastTransaction: string;
  co2: string;
  investmentStep: string;
  sumSubVerify?: boolean;
  pandaDocVerify?: boolean;
}

interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  token: string | null;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  signUp: (credentials: SignUpCredentials) => Promise<void>;
  transactions?: IStripeTransactionsStatus[]
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpCredentials {
  email: string;
  password: string;
  name: string;
  lastName: string;
  deal_amount: string,
  deal_return: string,
  deal_term: string,
}

interface IStripeTransactionsStatus {
  id: string;
  stripeEventId: string | null;
  deviceId: string | null;
  customerId: string | null;
  email: string | null;
  name: string | null;
  amountSpent: number | null;
  co2SurveyResult: number | null;
  co2reduced: number | null;
  transactionStatus: string | null;
  co2reducedPercentage: number | null;
  currency: string | null;
  tokenId: number | null;
  uri: string | null;
  chargeId: string | null;
  emailSend: boolean | null;
}


const AuthContext = createContext<AuthContextType | null>(null)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [token, setToken] = useState<string | null>(null)
  const [transactions, setTransactions] = useState<IStripeTransactionsStatus[]>([])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setIsLoading(true)
      AuthService.getProfile(token)
        .then((data) => {
          setUser(data)
          getAllTransactions(data.email, {
            headers: { Authorization: `Bearer ${token}` },
          }).then((data) => {
              setTransactions(data)
            },
          )
        })
        .catch((error) => {
          console.error('Token verification error:', error)
          setUser(null)
        })
        .finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [])

  const signIn = async (credentials: SignInCredentials) => {
    setIsLoading(true)
    try {
      const { accessToken } = await AuthService.login(credentials)
      setToken(accessToken)
      localStorage.setItem('token', accessToken)
      const userProfile = await AuthService.getProfile(accessToken)
      setUser(userProfile)
    } catch (error) {
      console.error('Sign in error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const signUp = async (credentials: SignUpCredentials) => {
    setIsLoading(true)
    try {
      const { accessToken } = await AuthService.signUp(credentials)
      setToken(accessToken)
      localStorage.setItem('token', accessToken)
      const userProfile = await AuthService.getProfile(accessToken)
      setUser(userProfile)
    } catch (error) {
      console.error('Sign in error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const signOut = () => {
    localStorage.removeItem('token')
    setUser(null)
  }

  const value = { user, token, isLoading, signIn, signOut, signUp, transactions }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
