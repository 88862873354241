import axios, { AxiosRequestConfig } from 'axios'
import config from '../config'
import { getMachineId } from '../utils'

interface RequestTokenResponse {
  message: string;
  url?: string;
}

interface VerifyTokenResponse {
  url: string;
  isUser: boolean;
}

interface SignUpResponse {
  accessToken: string;
}

interface AuthUserModel {
  id: string;
  email: string;
  name: string;
  TypeAlias: string;
  lastTransaction: string;
  co2: string;
  investmentStep: string;
}

const protocol = config.apiEndpoint?.includes('localhost') ? 'http' : 'https'
export const client = axios.create({
  baseURL: `${protocol}://${config.apiEndpoint}`,
  headers: {
    'Content-Type': 'application/json',
    'device-id': getMachineId(),
  },
})

export const getSurvey = async (ref: string | null) => {
  return await client.get(`/survey?ref=${ref}`).then((response) => response.data)
}

// eslint-disable-next-line
export const postSurvey = async (data: any) => {
  return await client.post('/survey', data).then((response) => response.data)
}

export const getSurveyRef = async (ref: string | null) => {
  return await client.get(`/survey/myref?ref=${ref}`).then((response) => response.data)
}

export const getSurveyTakeAnAction = async (ref?: string | null) => {
  return await client.get(`/survey/takeAnAction?ref=${ref}`).then((response) => response.data)
}

export const getSurveyFootprint = async (ref: string | null) => {
  return await client.get(`/survey/footprint?ref=${ref}`).then((response) => response.data)
}

// export const getSurveyFeedback = async (ref?: string) => {
//   return await client.get(`/survey/feedback?ref=${ref}`).then((response) => response.data)
// }

// eslint-disable-next-line
export const postSurveyFeedback = async (data: any) => {
  return await client.post('/survey/feedback', data).then((response) => response.data)
}

// User
// eslint-disable-next-line
export const postUser = async (data: any) => {
  return await client.post('/user', data).then((response) => response.data)
}

// Join private beta
// eslint-disable-next-line
export const joinPrivateBeta = async (data: any) => {
  return await client.post('/subscribePrivateBeta', data).then((response) => response.data)
}

// Logs
// eslint-disable-next-line
export const postLog = async (data: any) => {
  return await client.post('/logs/survey', data).then((response) => response.data)
}
// Share-link
export const getShareLink = async () => {
  return await client.get('/shareLink').then((response) => response.data)
}
// Get session
export const getSession = async () => {
  return await client.get('/webhook/generate-session').then((response) => response.data)
}

// Check transaction status
export const checkTransactionStatus = async (id: string | null) => {
  return client.get(`/webhook/checkTransaction/${id}`).then((response) => response.data)

}

// eslint-disable-next-line
export const addWaitlist = async (data: any) => {
  return await client.post('/waitlist', data).then((response) => response.data)
}
export const checkCode = (code: string) =>
  client.post('/codes/check', { code }).then((res) => res.data)

// eslint-disable-next-line
export const postFeedback = async (data: any) => {
  return await client.post('/feedback', data).then((response) => response.data)
}

// eslint-disable-next-line
export const postFunnel = async (data: any) => {
  return await client.post('/funnel', data).then((response) => response.data)
}

export const createPaymentIntent = async (data: unknown) => {
  return await client
    .post('/payments/create-payment-intent', data)
    .then((response) => response.data)
}

export const createPaymentCustomer = async (data: unknown) => {
  return await client.post('/payments/create-customer', data).then((response) => response.data)
}

export const getPaymentStatus = async (id: string | null): Promise<Record<string, string>> => {
  return await client.get(`/payments/check?id=${id}`).then((response) => response.data)
}

export const getAllTransactions = async (email?: string, config?: AxiosRequestConfig) => {
  return await client.get(`/payments/all?email=${email}`, config).then((response) => response.data)
}

export const updateTranslations = async (data: unknown) => {
  return await client.post('/translations/update', data).then((response) => response.data)
}

export const getTranslations = async (lang = 'en') => {
  return await client.get(`/translations/${lang}`).then((response) => response.data)
}

export const createTranslation = async (data: unknown) => {
  return await client.post('/translations', data).then((response) => response.data)
}

export const getReferenceTransaction = async (referenceId: string) => {
  return await client
    .get(`/payments/reference?referenceId=${referenceId}`)
    .then((response) => response.data)
}

export const AuthService = {
  requestToken: async (email: string, password?: string): Promise<RequestTokenResponse> => {
    try {
      const params: {
        email: string,
        password?: string
      } = { email }
      if (password) {
        params.password = password
      }

      const response = await client.get<RequestTokenResponse>('/auth/request-token', { params })
      return response.data
    } catch (error) {
      throw error
    }
  },
  verifyToken: async (token: string): Promise<VerifyTokenResponse> => {
    try {
      const response = await client.get<VerifyTokenResponse>('/auth/verify-token', {
        params: { token },
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  signUp: async (data: {
    email: string,
    name: string,
    lastName: string,
    password: string,
    deal_amount: string,
    deal_return: string,
    deal_term: string,
  }): Promise<SignUpResponse> => {
    return await client.post('/auth/signup', data).then((response) => response.data)
  },
  login: async (data: {
    email: string,
    password: string,
  }): Promise<SignUpResponse> => {
    return await client.post('/auth/login', data).then((response) => response.data)
  },
  getProfile: async (token: string): Promise<AuthUserModel> => {
    return await client.get('/auth/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((response) => response.data)
  },
  verifyEmail: async (email: string): Promise<boolean> => {
    return await client.post('/auth/validate-email', { email }).then((response) => response.data)
  },
  requireResetPassword: async (email: string): Promise<boolean> => {
    return await client.post('/auth/request-password-reset', { email }).then((response) => response.data)
  },
  resetPassword: async (data: {
    token: string,
    newPassword: string,
  }): Promise<boolean> => {
    return await client.post('/auth/reset-password', data).then((response) => response.data)
  },
  updateInvestmentStep: async (data: {
    email: string
    investmentStep: string
  }) => {
    const token = await localStorage.getItem('token')
    return await client.post('/auth/update-investment-step', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
  updatePandaDocVerify: async (data: {
    email: string
    pandaDocVerify: boolean
  }) => {
    const token = await localStorage.getItem('token')
    return await client.post('/auth/update-panda-doc', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
}

export const sendTelegramSnowball = async (data: unknown) => {
  return await client.post('/payments/snowball', data).then((response) => response.data)
}

export const postSignUp = async (data: {
  email: string,
  name: string,
}) => {
  return await client.post('/waitlist/handleSingUp', data).then((response) => response.data)
}

export const handleETF = async (data: {
  name: string,
  lastName: string,
  answer: string,
  email: string,
}) => {
  return await client.post('/feedback/handle-etf', data).then((response) => response.data)
}

export const updateLastTransaction = async (data: {
  email: string,
  lastTransaction: string,
}) => {
  return await client.post('/auth/update-last-transaction', data).then((response) => response.data)
}

export const getPandaDocSession = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  term: string;
  amount: string;
  returnRate: string;
}): Promise<{ sessionId: string; expiresAt: string }> => {
  const token = await localStorage.getItem('token')
  return await client.post('/panda-doc/new-session', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.data)
}

export const getSumSubAccessToken = async (data: {
  internalUserID: string
}) => {
  const token = await localStorage.getItem('token')
  return await client.post('/sum-sub/new-access-token', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.data)
}

export const sendTelegramMessage = async (data: {
  text: string;
}) => {
  return await client.post('/feedback/telegram-message', data).then((response) => response.data)
}
