import React, { useEffect, useRef } from 'react'
import Header from './components/Header'
import MainBlock from './components/MainBlock'
import Opportunities from './components/Opportunities'
// import TextBlock from './components/TextBlock'
import SignUp from './components/SignUp'
// import Testimonials from './components/Testimonial'

// import HowItWorks from './components/HowItWorks'
import Projects from './components/Projects'
import { MobileVideo, Video } from './components/Video'
import Footer from './components/Footer'
import { Footer as MobileFooter } from '../LandingV3/components'
import ReactGA from 'react-ga4'

const LandingV4: React.FC = () => {

  useEffect(() => {
    const hash = window.location.hash
    if (hash && hash === '#signup') {
      const teamSection = document.getElementById('signup')
      if (teamSection) {
        window.scrollTo({
          top: teamSection.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  }, [])

  const blocksRef = useRef({})

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        const blockName = entry.target.getAttribute('data-blockname')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (blocksRef.current[blockName!]) return
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        blocksRef.current[blockName!] = true

        ReactGA.event({
          category: `Scrolling HomePage ${blockName}`,
          action: `Scrolled to block HomePage ${blockName}`,
          label: `Scrolled to block HomePage ${blockName}`,
        })
        if (Object.keys(blocksRef.current).length === 9) {
          ReactGA.event({
            category: 'Scrolling HomePage',
            action: 'Scrolled through entire page HomePage',
          })
        }
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  })

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-blockname]')
    blocks.forEach((block) => observer.observe(block))
    return () => {
      blocks.forEach((block) => observer.unobserve(block))
    }
  }, [observer])

  return (
    <div className={'min-w-full w-full flex flex-col bg-white'}>
      <Header />
      <div className={'w-full sm:h-[76px] h-[62px]'} />
      <div data-blockname='HeroBlock'><MainBlock /></div>
      <div data-blockname='Opportunities'><Opportunities /></div>
      {/* <div data-blockname='TextBlock'><TextBlock /></div>*/}
      <div data-blockname='Video'><Video /></div>
      <div data-blockname='MobileVideo' className={'sm:hidden bg-[#E5E5DF]'}>
        <MobileVideo />
      </div>
      <div data-blockname='Projects'><Projects /></div>
      {/* <div data-blockname='HowItWorks'><HowItWorks /></div>*/}
      
      {/* <div data-blockname='Testimonials'><Testimonials /></div>*/}
      <div data-blockname='SignUp' id={'signup'}>
        <SignUp />
      </div>
      <Footer />
      <div className={'md:hidden'}>
        <MobileFooter />
      </div>
    </div>
  )
}

export default LandingV4