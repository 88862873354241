import React, { useEffect, useState } from 'react'
import { InvestmentStep } from '../../index'
import DocumentList, { IDocument } from '../DocumentList'
import DocumentSign from '../DocumentSign'
import { useAuth } from '../../../../utils/AuthContext/AuthProvider'
import { AuthService, getPandaDocSession, getSumSubAccessToken, sendTelegramMessage } from '../../../../utils/api'
import { CircularProgress } from '@mui/material'
import SumsubWebSdk from '@sumsub/websdk-react'
import TransferFunds from '../TransferFunds'
import { useQuery } from 'react-query'

interface StepperMenuProps {
  currentStep: string;
  setStep: (step: InvestmentStep) => void;
  amount: string;
  term: string;
  returnRate: string;
}

type SumSubMessageType =
  | 'idCheck.onApplicantLoaded'
  | 'idCheck.onCheckUpdated'
  | 'idCheck.onError'
  | string;

interface ReviewResult {
  reviewAnswer: string;
  rejectLabels: string[];
  reviewRejectType: string;
}

interface SumSubMessagePayload {
  reviewResult?: ReviewResult;

  [key: string]: any; // for other message payloads
}

const documents: IDocument[] = [
  { id: '1', title: 'Subscription form', type: 'sign', viewed: false, url: '../../assets/QantaOfferingStatement.pdf' },
  { id: '2', title: 'KYC form', type: 'sign', viewed: false, url: 'path/to/kyc_form.pdf' },
  {
    id: '3',
    title: 'Qanta summary',
    type: 'read',
    viewed: false,
    url: 'https://pyrpose.s3.eu-central-1.amazonaws.com/Qanta%20Summary.pdf',
  },
  {
    id: '4',
    title: 'Qanta Offering Statement',
    type: 'read',
    viewed: false,
    url: 'https://pyrpose.s3.eu-central-1.amazonaws.com/Qanta%20Offering%20Statement.pdf',
  },
]

const fetchPandaDocSession = async (data: {
  name: string;
  email: string;
  amount: string;
  term: string;
  returnRate: string;
}) => {
  const parts = data?.name.split(' ');
  const filteredData = {
    firstName: parts.length >= 1 ? parts[0] : '',
    lastName: parts.length >= 1 ? parts[1] : '',
    email: data?.email ?? '',
    term: data.term,
    amount: data.amount,
    returnRate: data.returnRate,
  };
  return await getPandaDocSession(filteredData);
};

const StepperMenu: React.FC<StepperMenuProps> = ({ currentStep, setStep, amount, term, returnRate }) => {
  const { user } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentDocumentUrl, setCurrentDocumentUrl] = useState('')
  const [documentSigned, setDocumentSigned] = useState(user?.pandaDocVerify ?? false)
  const [kycDone, setKycDone] = useState(user?.sumSubVerify ?? false)
  const [isLoading, setIsLoading] = useState(false)
  const [sumSubToken, setSumSubToken] = useState('')
  const [sumSubUserId, setSumSubUserId] = useState('')

  const handleOpenModal = (url: string) => {
    setCurrentDocumentUrl(url)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCurrentDocumentUrl('')
  }

  const handleCloseModalKYC = () => {
    setSumSubToken('')
    setSumSubUserId('')
  }

  const handleContinue = () => {
    setStep(InvestmentStep.DocumentsValidation)
  }

  const handleSignDocument = async (id: string) => {
    setIsLoading(true)
    try {
       await fetchPandaDocSession({
        name: user?.name ?? '',
        email: user?.email ?? '',
        amount,
        term,
        returnRate,
      }).then(async (data) => {
         if (data?.sessionId) {
           await sendTelegramMessage({
             text: `${user?.name} (${user?.email}) opened the document to sign it`
           });
           setIsLoading(false)
           handleOpenModal(`https://app.pandadoc.com/s/${data.sessionId}`);
         } else {
           setIsLoading(false)
         }
       })
    } catch (error) {
      setIsLoading(false)
    }
  };


  const handleFillForm = async (id: string) => {
    setIsLoading(true)
    await getSumSubAccessToken({
      internalUserID: user?.id ?? '',
    }).then(async (data) => {
      setSumSubToken(data.token)
      setSumSubUserId(data.userId)
      await sendTelegramMessage({ text: `${user?.name}(${user?.email})  open KYC` })
      setIsLoading(false)
    }).catch(() => setIsLoading(false))
  }

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      const { type } = event.data || {}

      switch (type) {
      case 'session_view.document.loaded':
        console.log('Session view is loaded')
        break
      case 'session_view.document.completed':
        await sendTelegramMessage({ text: `${user?.name}(${user?.email}) successfully sign document` })
        setDocumentSigned(true)
        await AuthService.updatePandaDocVerify({ email: user?.email ?? '', pandaDocVerify: true })
        break
      case 'session_view.document.exception':
        setDocumentSigned(false)
        await AuthService.updatePandaDocVerify({ email: user?.email ?? '', pandaDocVerify: false })
        break
      default:
        break
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleSumSubMessage = async (type: SumSubMessageType, payload: SumSubMessagePayload) => {
    switch (type) {
    case 'idCheck.onApplicantLoaded':
      console.log('Applicant loaded')
      break
    case 'idCheck.onCheckUpdated':
      if (payload.reviewResult?.reviewAnswer === 'GREEN') {
        setKycDone(true)
        setSumSubToken('')
        setSumSubUserId('')
      }
      break
    case 'idCheck.onApplicantStatusChanged':
      if (payload.reviewResult?.reviewAnswer === 'GREEN') {
        setKycDone(true)
        setSumSubToken('')
        setSumSubUserId('')
      }
      break
    case 'idCheck.onApplicantSubmitted':
      await sendTelegramMessage({ text: `${user?.name}(${user?.email}) filled KYC form please update it approve it` })
      break
    default:
      console.log(`Unhandled message type: ${type}`)
    }
  }

  const handleSumSubError = (error: any) => {
    console.error('SumSub WebSDK error:', error)
  }

  const activeStep = () => {
    switch (currentStep) {
    case InvestmentStep.InvestmentDocuments:
      return <DocumentList documents={documents} onContinue={handleContinue} />
    case InvestmentStep.DocumentsValidation:
      return (
        <DocumentSign
          documents={documents}
          amount={amount}
          term={term}
          returnRate={returnRate}
          onSignDocument={handleSignDocument}
          onFillForm={handleFillForm}
          documentSigned={documentSigned}
          kycDone={kycDone}
          onContinue={() => {
            setStep(InvestmentStep.TransferOfFunds)
          }}
        />
      )
    case InvestmentStep.TransferOfFunds:
      return (<TransferFunds amount={amount} user={user ?? undefined} />)
    default:
      return <div />
    }
  }


  const expirationHandler = async (): Promise<string> => {
    const response = await getSumSubAccessToken({
      internalUserID: user?.id ?? '',
    })
    return response.token
  }

  return (
    <>
      {isModalOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-4 rounded-lg shadow-lg w-full max-w-3xl max-h-[80vh]'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-xl font-semibold'>Sign Document</h3>
              <button onClick={handleCloseModal} className='text-gray-500 hover:text-gray-800'>&times;</button>
            </div>
            <iframe src={currentDocumentUrl} width='100%' height='600' className='rounded-lg' title='Document Sign' />
          </div>
        </div>
      )}
      {isLoading && (
        <div className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white py-[300px] rounded-lg shadow-lg w-full max-w-3xl'>
            <CircularProgress size={'90px'} color={'primary'} />
          </div>
        </div>
      )}
      {sumSubToken && sumSubUserId && (
        <div className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50'>
          <div
            className='bg-white p-4 rounded-lg shadow-lg w-full max-w-3xl max-h-[80vh] min-h-[50vh] overflow-y-scroll'>
            <div className='flex justify-between items-center mb-4'>
              <h3 className='text-xl font-semibold'>KYC form</h3>
              <button onClick={handleCloseModalKYC} className='text-gray-500 hover:text-gray-800'>&times;</button>
            </div>
            <SumsubWebSdk
              accessToken={sumSubToken}
              expirationHandler={expirationHandler}
              config={{
                lang: 'en',
              }}
              onMessage={handleSumSubMessage}
              onError={handleSumSubError}
            />
          </div>
        </div>
      )}
      <div className='bg-[#F2F2EF] font-Inter p-4 rounded-lg shadow-md w-full '>
        {activeStep()}
      </div>
    </>
  )
}

export default StepperMenu
