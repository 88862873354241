import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { Markup } from 'interweave'

interface DataPoint {
  year: number;
  principle: number;
  interestIncome: number;
}

const Chart: React.FC = () => {


  const calculateInterestIncome = (principle: number, interestRate: number, years: number): number => {
    return principle * interestRate * years
  }

  const generateData = (initialYear: number, principle: number, interestRate: number, numberOfYears: number): DataPoint[] => {
    const data: DataPoint[] = [];
    for (let i = 0; i <= numberOfYears; i++) {
      if (i === numberOfYears) {
        data.push({
          year: initialYear + i,
          principle: principle,
          interestIncome: calculateInterestIncome(principle, interestRate, i - 1),
        });
      } else {
        const year = initialYear + i;
        const interestIncome = calculateInterestIncome(principle, interestRate, i);
        data.push({ year, principle, interestIncome });
      }
    }
    return data;
  };

  const initialYear = new Date().getFullYear()
  const principle = parseInt(localStorage.getItem('fetchedAmount') ?? '10000')
  const interestRate = (parseFloat(localStorage.getItem('fetchedReturnRate') ?? '7') / 100) ?? 0.07 // 7%
  const numberOfYears = parseFloat(localStorage.getItem('fetchedTerm') ?? '3')

  const data = generateData(initialYear, principle, interestRate, numberOfYears);
  const svgRef = useRef<SVGSVGElement | null>(null)

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const width = 600
    const height = 330
    const margin = { top: 20, right: 30, bottom: 30, left: 65 }

    const x = d3.scaleLinear<number>()
      .domain([initialYear,  data[data.length - 1].year])
      .range([margin.left, width - margin.right])

    const y = d3.scaleLinear()
      .domain([principle * 0.8, principle +  data[data.length - 1].interestIncome])
      .nice()
      .range([height - margin.bottom, margin.top])

    svg.selectAll('*').remove()

    svg.append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickFormat(d3.format('d') as any).ticks(numberOfYears))
      .selectAll('text')
      .style('font-size', '15px')
      .style('font-family', 'Inter')
      .attr('transform', `translate(${(width -80) / numberOfYears / 2},5)`)

    svg.select('.tick:last-of-type').style('display', 'none')

    const yAxis = svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(5).tickFormat(d => `€${d}`))

    yAxis.selectAll('text')
      .style('font-size', '15px')
      .style('font-family', 'Inter')

    // Remove the y-axis line
    yAxis.select('path').style('display', 'none')
    yAxis.selectAll('line').style('display', 'none')

    svg.selectAll('g.grid')
      .data(y.ticks(5))
      .enter()
      .append('line')
      .attr('class', 'grid')
      .attr('x1', margin.left)
      .attr('x2', width - margin.right)
      .attr('y1', d => y(d))
      .attr('y2', d => y(d))
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '2 2')

    const principleArea = d3.area<DataPoint>()
      .x(d => x(d.year))
      .y0(y( principle * 0.8 ))
      .y1(d => y(d.principle))

    const interestLine = d3.line<DataPoint>()
      .x(d => x(d.year))
      .y(d => y(d.principle + d.interestIncome))
      .curve(d3.curveStepAfter)

    svg.append('path')
      .datum(data)
      .attr('fill', 'url(#gradient1)')
      .attr('stroke', 'none')
      .attr('d', principleArea)

    // Draw filled interest area without stroke
    const interestArea = d3.area<DataPoint>()
      .x(d => x(d.year))
      .y0(d => y(d.principle))
      .y1(d => y(d.principle + d.interestIncome))
      .curve(d3.curveStepAfter)

    svg.append('path')
      .datum(data)
      .attr('fill', 'url(#gradient2)')
      .attr('stroke', 'none')
      .attr('d', interestArea)

    // Draw top line for principle area
    const principleLine = d3.line<DataPoint>()
      .x(d => x(d.year))
      .y(d => y(d.principle))

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#37992E')
      .attr('stroke-width', 2)
      .attr('d', principleLine)

    // Draw top line for interest area
    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#FF6F00')
      .attr('stroke-width', 2)
      .attr('d', interestLine)

    const defs = svg.append('defs')

    defs.append('linearGradient')
      .attr('id', 'gradient1')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#ABE1B0' },
        { offset: '40%', color: '#ABE1B090' },
        { offset: '100%', color: '#DCF2DE40' },
      ])
      .enter().append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color)

    defs.append('linearGradient')
      .attr('id', 'gradient2')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%')
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#FFB881' },
        { offset: '100%', color: '#FFB881' },
      ])
      .enter().append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color)

  }, [data])

  return (
    <div className=' flex items-center bg-white justify-center gap-6 pt-[64px]  pb-[100px] w-full max-w-[100vw]'>
      <div className={'flex-col justify-start items-start px-4  flex max-w-[1200px]'}>
        <div className='flex-col justify-center items-center gap-2 flex w-full max-w-[100%]'>
          <div className={'w-full flex flex-row justify-center items-center'}>
            <div
              className=' text-green-600 text-[12px] font-bold uppercase'
            >my profile
            </div>
          </div>
          <div className={' flex-col flex items-center justify-center pb-[16px] w-full'}>
            <div className='max-sm:w-full text-start text-[28px] font-semibold font-Inter'>You are making massive action!
            </div>
            <Markup className={'text-[17px] leading-[24px] max-w-[70%] mt-2 '}
                    content={'You are now part of an elite group of forward-thinking individuals whose commitment to a sustainable future is unmatched. By investing in Pyrpose, you\'ve demonstrated just how much you care about leaving a healthy world for generations to come.'} />
          </div>
        </div>
        <div className={'flex flex-row gap-4 justify-center  mt-10 w-full'}>
          <div className={'w-[55%] py-8 px-10 bg-[#F2F2EF] rounded-[12px]  '}>
            <div className={'flex flex-row items-center justify-between pb-4'}>
              <div className={'font-Inter text-[20px] leading-[28px] font-semibold'}>
                Investment Chart
              </div>
              <div className={'flex flex-row gap-8 items-center'}>
                <div className={'flex flex-row gap-2 items-center'}>
                  <div className={'w-4 h-4 bg-[#37992E] rounded-full '} />
                  <div className={'font-Inter text-[13px] leading-[15px]'}>
                    Principle
                  </div>
                </div>
                <div className={'flex flex-row gap-2 items-center'}>
                  <div className={'w-4 h-4 bg-[#FF6F00] rounded-full'} />
                  <div className={'font-Inter text-[13px] leading-[15px]'}>
                    Interest income
                  </div>
                </div>
              </div>
            </div>
            <div className={'min-h-[330px] w-full h-full'}>
              <svg ref={svgRef} width='100%'
                   height='100%'
                   viewBox={'0 0 572 330'}
                   preserveAspectRatio='xMinYMin meet'></svg>
            </div>
          </div>
          <div className={'w-[45%] bg-[#F2F2EF] text-start py-8 px-10 flex flex-col justify-between rounded-[12px]'}>
            <div className={'flex flex-col'}>
              <div className={'text-[20px] font-Inter font-semibold pb-2 leading-[28px]'}>
                Investment Summary
              </div>
              <div className={'text-[15px] font-Inter leading-[20px] text-[#525252]'}>
                Your total investment on Pyrpose to date
              </div>
              <div className={'text-[36px] font-Inter font-semibold py-6 leading-[44px]'}>
                {principle} CHF
              </div>
              <div className={'h-[1px] w-full bg-[#00000008]'} />
              <div className={'flex flex-col w-full py-4 gap-2'}>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Interest rate
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    {(interestRate * 100).toFixed(2)}%
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Interest income
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    {(principle * interestRate * numberOfYears).toFixed(0)} CHF
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Future balance
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    {(principle + (principle * interestRate * numberOfYears)).toFixed(0)} CHF
                  </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                  <div className={'text-[17px] font-Inter leading-[20px] text-[#525252]'}>
                    Next interest payout
                  </div>
                  <div className={'text-[17px] font-Inter font-semibold  leading-[20px] text-[#0F0F0F]'}>
                    {initialYear + numberOfYears}
                  </div>
                </div>
              </div>
            </div>
            <a
              className={'bg-black rounded-[12px] w-full py-3 text-center text-[17px] font-semibold font-Inter text-white '}
              href={'https://projects.pyrpose.io'}>
              Invest more
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chart








