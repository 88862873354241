import React from 'react'
import PdfIcon from '../../assets/pdf-icon.svg'
import Eye from '../../assets/eye.svg'
import SandTime from './sandTimer'

interface IDocument {
  id: string;
  title: string;
  type: 'read' | 'sign';
  viewed: boolean;
  url: string;
}

interface StartInvestmentProps {
  documents: IDocument[];
  onSignDocument: (id: string) => void;
  onFillForm: (id: string) => void;
  documentSigned: boolean
  kycDone: boolean
  onContinue: () => void;
  amount: string;
  term: string;
  returnRate: string;

}

const DocumentSign: React.FC<StartInvestmentProps> = ({ documents, onSignDocument, onFillForm, documentSigned, kycDone, onContinue, amount, term, returnRate }) => {
  const signDocs = documents.filter(doc => doc.type === 'sign')
  const readDocs = documents.filter(doc => doc.type === 'read')

  const handleView = (id: string, url: string) => {
    window.open(url, '_blank')
  }


  return (
    <>
      <h2 className='text-xl font-bold mb-4 text-start'>2. Document Validation</h2>
      <p className='text-gray-600 mb-4 text-start'>Please find your investment summary below.</p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-8'>
        <div>
          <h3 className='text-lg font-semibold mb-2 text-start'>You are about to invest in:</h3>
          <ul className='list-none p-0'>
            <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Location:</span><span
              className='font-semibold'>Davos</span></li>
            <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Investment type:</span><span
              className='font-semibold'>Junior Loan</span></li>
            <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Amount:</span><span
              className='font-semibold'>CHF {amount}</span></li>
            <li className='flex justify-between py-2 border-b-[1px] border-b-gray'><span>Period:</span><span
              className='font-semibold'>{term} years</span></li>
            <li className='flex justify-between py-2'><span>Yield:</span><span
              className='font-semibold'>{returnRate} %</span>
            </li>
          </ul>
        </div>
        <SandTime />
      </div>

      <p className='text-gray-700 mb-4 font-semibold text-start'>Please find the documents which need to be signed
        electronically.</p>
      <p className='text-gray-500 mb-8 text-start'>
        If the documents are not signed and completed within the next 48 hours your investment spot will no
        longer stay reserved.
      </p>

      <div className='mb-8'>
        {signDocs.map(doc => (
          <div key={doc.id}
               className='flex cursor-pointer items-center justify-between p-4 bg-white rounded-lg shadow-sm mb-2'>
            <div className='flex items-center gap-2'>
              <img src={PdfIcon} alt={'PdfIcon'} height={40} width={40} />
              <span className={'font-semibold'}>{doc.title}</span>
            </div>
            <button
              onClick={() => doc.title.includes('KYC') ? onFillForm(doc.id) : onSignDocument(doc.id)}
              className='bg-[#37992E] font-semibold text-white px-4 py-2 rounded-lg'
            >
              {doc.title.includes('KYC') ? kycDone ? 'Completed' : 'Fill up online' : documentSigned ? 'Completed' : 'E-sign the document'}
            </button>
          </div>
        ))}
      </div>

      <div className='mb-8'>
        <h3 className='text-lg font-semibold mb-4'>Documents to read</h3>
        {readDocs.map(doc => (
          <div key={doc.url + doc.id} onClick={() => handleView(doc.id, doc.url)}
               className='flex cursor-pointer items-center justify-between p-4 bg-white rounded-lg shadow-sm mb-2'>
            <div className='flex items-center gap-4'>
              <img src={PdfIcon} alt={'PdfIcon'} height={40} width={40} />
              <span className=' font-semibold'>{doc.title}</span>
            </div>
            <div>
              <img src={Eye} width={20} height={20} alt={'Eye'} />
            </div>
          </div>
        ))}
      </div>
      <p className='text-gray-700 mb-4 font-semibold text-start'>To continue to the next step, you need to sign the
        documents and complete the KYC (Know Your Customer) process. Once your KYC information is approved, you will be
        able to proceed further. Please note that this verification process may take up to a couple of hours. Thank you
        for your patience.</p>
      <button
        onClick={onContinue}
        disabled={!(kycDone && documentSigned)}
        className={`mt-4 bg-black h-[48px] ${!(kycDone && documentSigned) && 'opacity-[50%]'} w-1/2 text-white px-4 py-2 rounded-lg font-semibold mb-2`}

      >
        Continue
      </button>
    </>
  )
}

export default DocumentSign
