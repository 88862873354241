import React, { useEffect, useState } from 'react'
import SandTimer from '../../assets/sand-timer.svg'

const SandTime = () => {
  const [timeRemaining, setTimeRemaining] = useState(48 * 60 * 60);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(timeRemaining);

  return (
    <div className='text-center flex items-center flex-col'>
      <h3 className='text-lg font-semibold mb-2'>Time to execute</h3>
      <img src={SandTimer} className={'w-[53%]'} alt={'SandTimer'} />
      <div className='flex justify-around w-[60%] space-x-2 mt-5 text-3xl font-semibold text-gray-800'>
        <div className='flex justify-around w-full space-x-2 text-3xl font-semibold text-gray-800'>
          <div className='flex flex-col items-center w-1/3'><span className={'p-2 bg-green-400 bg-opacity-50 rounded'}>{hours}</span><span className='text-xs'>hours</span></div>
          <div className='flex flex-col items-center w-1/3'><span className={'p-2 bg-green-400 bg-opacity-50 rounded'}>{minutes}</span><span className='text-xs'>minutes</span></div>
          <div className='flex flex-col items-center w-1/3'><span className={'p-2 bg-green-400 bg-opacity-50 rounded'}>{seconds}</span><span className='text-xs'>seconds</span></div>
        </div>
      </div>
    </div>
  )
}

export default SandTime