import React from 'react'
import PdfIcon from '../../assets/pdf-icon.svg'
import Eye from '../../assets/eye.svg'
import EyeSlash from '../../assets/eye-slash.svg'

export interface IDocument {
  id: string;
  title: string;
  type: 'read' | 'sign';
  viewed: boolean;
  url: string;
}

interface DocumentListProps {
  documents: IDocument[];
  onContinue: () => void;
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onContinue }) => {
  const [docs, setDocs] = React.useState(documents)

  React.useEffect(() => {
    setDocs(documents)
  }, [documents])

  const handleView = (id: string, url: string) => {
    setDocs(prevDocs =>
      prevDocs.map(doc =>
        doc.id === id ? { ...doc, viewed: true } : doc,
      ),
    )
    window.open(url, '_blank')
  }

  const allViewed = docs.filter(doc => doc.type !== 'sign').every(doc => doc.viewed)

  return (
    <>
      <h2 className='text-xl font-bold mb-4 text-start'>1. Investment documents</h2>
      <p className='text-gray-600 mb-4 text-start'>
        The investment package includes all the documents that you must complete and sign in order to complete your
        investment.
      </p>

      <div className='mb-8 text-start'>
        <h3 className='text-lg font-semibold font-Inter'>Documents to read</h3>
        <p className='text-gray-500  font-Inter'>Here are the informative documents you need to read.</p>
        <p className='font-semibold font-Inter my-4'>Please open and read the documents below to proceed to the next
          step.</p>
        {docs.filter(doc => doc.type === 'read').map(doc => (
          <div key={doc.id + 'list'} onClick={() => handleView(doc.id, doc.url)}
               className='flex items-center justify-between p-4 bg-white rounded-lg shadow-sm mb-2 cursor-pointer'>
            <div className='flex items-center gap-4'>
              <img src={PdfIcon} alt={'PdfIcon'} height={40} width={40} />
              <span className={'font-semibold'}>{doc.title}</span>
            </div>
            <div
            >
              {doc.viewed ? <img src={Eye} width={20} height={20} alt={'Eye'} /> :
                <img src={EyeSlash} width={20} height={20} alt={'Eye'} />}
            </div>
          </div>
        ))}
      </div>

      {/* <div> */}
      {/*   <h3 className='text-lg font-semibold text-start'>Documents to read and sign online</h3> */}
      {/*   <p className='text-gray-500 mb-4 text-start'>Here are the subscription documents that you must fill out and */}
      {/*     sign, in order */}
      {/*     to validate your investment.</p> */}
      {/*   {docs.filter(doc => doc.type === 'sign').map(doc => ( */}
      {/*     <div onClick={() => handleView(doc.id, doc.url)} key={doc.id} */}
      {/*          className='flex cursor-pointer items-center justify-between p-4 bg-white rounded-lg shadow-sm mb-2'> */}
      {/*       <div className='flex items-center gap-4'> */}
      {/*         <img src={PdfIcon} alt={'PdfIcon'} height={40} width={40} /> */}
      {/*         <span className='text-red-500 font-semibold'>{doc.title}</span> */}
      {/*       </div> */}
      {/*       <div> */}
      {/*         {doc.viewed ? <img src={Eye} width={20} height={20} alt={'Eye'} /> : */}
      {/*           <img src={EyeSlash} width={20} height={20} alt={'Eye'} />} */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   ))} */}
      {/* </div> */}

      <button
        onClick={onContinue}
        disabled={!allViewed}
        className={`mt-4 bg-black h-[48px] ${!allViewed && 'opacity-[50%]'} w-1/2 text-white px-4 py-2 rounded-lg font-semibold mb-2`}

      >
        Continue
      </button>
    </>
  )
}

export default DocumentList
