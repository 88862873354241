import React from 'react'
import { HeaderBlock } from '../PrivateProfile/components'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../utils/AuthContext/AuthProvider'
import { Markup } from 'interweave'
import Footer from '../../LandingV4/components/Footer'
import { Footer as MobileFooter } from '../../LandingV3/components'
import config from '../../../config'
import { sendTelegramMessage } from '../../../utils/api'

const ReferralPage: React.FC = () => {
  const navigate = useNavigate()
  const { token: userToken, user } = useAuth()

  if (!user) {
    navigate('/login')
  }

  const protocol = config.apiEndpoint?.includes('localhost') ? 'http' : 'https'
  const shareUrl = `${protocol}://${config.inviteUrl}/from/${user?.id}`

  const handleCopy = async() => {

    navigator.clipboard.writeText(shareUrl)
      .then(async() => {
        await sendTelegramMessage({ text: `${user?.name} copied share link ` })
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }

  return (<div className={'w-full h-full flex-col bg-[#F2F2EF] min-h-screen flex items-center justify-center relative'}>
    <HeaderBlock name={user?.name} email={user?.email} />
    <div className={'flex flex-col w-full '}>
      <div className=' flex items-center bg-white justify-center gap-6 pt-[64px]  pb-[100px] w-full max-w-[100vw]'>
        <div className={'flex-col justify-start items-start px-4  flex max-w-[1200px]'}>
          <div className='flex-col justify-center items-center gap-2 flex w-full max-w-[100%]'>
            <div className={'w-full flex flex-row justify-center items-center'}>
              <div
                className=' text-green-600 text-[12px] font-bold uppercase'
              >Referral Policy
              </div>
            </div>
            <div className={' flex-col flex items-center justify-center pb-[16px] w-full'}>
              <div className='max-sm:w-full text-start text-[28px] font-semibold font-Inter'>Invite your friends to make more impact together
              </div>
              <Markup className={'text-[17px] leading-[24px] max-w-[70%] mt-2 '}
                      content={'Multiply your impact on the climate by inviting your friends to join our exclusive investment community. Together, we can create a sustainable future while also making a return on investments.'} />
            </div>
          </div>
          <div className='flex-row gap-8 justify-between  flex w-full max-w-[100%]'>
            <div className={'w-[70%] bg-[#F2F2EF] gap-6 flex  flex-col rounded-[12px] px-10 py-8 text-start justify-between'}>
              <div className={'font-semibold font-Inter text-[28px] leading-[32px]'}>
                How It Works
              </div>
              <div className={'w-full flex flex-row gap-4 '}>
              <div className={'w-2/3'}>
                <div className={'text-[17px] font-semibold leading-[24px]'}>
                  1. Copy Your Unique Referral Link
                </div>
                <p className={'text-[17px] text-[#525252] pt-1 pl-4 leading-[24px]'}>
                  Use the link provided below to invite your friends to our platform. <br/>
                  This link is unique to you and will help us track who signs up through your referral.
                </p>
              </div>
                <div className={'w-1/3 flex min-h-full rounded-[12px] border-[1px] border-black items-center justify-center text-center align-middle cursor-pointer'} onClick={handleCopy} >
                 <div className={'flex flex-row items-center gap-2 font-medium text-[17px] leading-[20px]'}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fillRule="evenodd" clipRule="evenodd" d="M12.5063 5.49395C12.8317 5.81939 12.8317 6.34702 12.5063 6.67246L6.67297 12.5058C6.34753 12.8312 5.81989 12.8312 5.49446 12.5058C5.16902 12.1804 5.16902 11.6527 5.49446 11.3273L11.3278 5.49395C11.6532 5.16851 12.1809 5.16851 12.5063 5.49395Z" fill="#0F0F0F"/>
                   <path fillRule="evenodd" clipRule="evenodd" d="M4.28633 7.82138C4.61177 8.14682 4.61177 8.67445 4.28633 8.99989L3.10782 10.1784C1.80607 11.4802 1.80607 13.5907 3.10782 14.8924C4.40957 16.1942 6.52012 16.1942 7.82187 14.8924L9.00038 13.7139C9.32581 13.3885 9.85345 13.3885 10.1789 13.7139C10.5043 14.0394 10.5043 14.567 10.1789 14.8924L9.00038 16.071C7.04776 18.0236 3.88193 18.0236 1.92931 16.071C-0.0233121 14.1183 -0.0233113 10.9525 1.92931 8.99989L3.10782 7.82138C3.43326 7.49594 3.9609 7.49594 4.28633 7.82138Z" fill="#0F0F0F"/>
                   <path fillRule="evenodd" clipRule="evenodd" d="M9.00038 1.92882C10.953 -0.0237996 14.1188 -0.0238004 16.0714 1.92882C18.0241 3.88144 18.0241 7.04727 16.0714 8.99989L14.8929 10.1784C14.5675 10.5038 14.0399 10.5038 13.7144 10.1784C13.389 9.85296 13.389 9.32533 13.7144 8.99989L14.8929 7.82138C16.1947 6.51963 16.1947 4.40908 14.8929 3.10733C13.5912 1.80559 11.4806 1.80559 10.1789 3.10733L9.00038 4.28584C8.67494 4.61128 8.1473 4.61128 7.82187 4.28584C7.49643 3.96041 7.49643 3.43277 7.82187 3.10733L9.00038 1.92882Z" fill="#0F0F0F"/>
                 </svg>
                   Copy Link</div>
                </div>
              </div>
              <div>
                <div className={'text-[17px] font-semibold leading-[24px]'}>
                  2. Share the Link
                </div>
                <p className={'text-[17px] text-[#525252] pt-1 pl-4 leading-[24px]'}>
                  Send this link to your friends, family, and professional network. <br/>
                  Encourage them to join our community of climate-conscious investors.
                </p>
              </div>
              <div>
                <div className={'text-[17px] font-semibold leading-[24px]'}>
                  3. Exclusive Access
                </div>
                <p className={'text-[17px] text-[#525252] pt-1 pl-4 leading-[24px]'}>
                  Your referrals will receive access to join after they are approved by our team,
                  ensuring that all members are genuinely committed to our mission.
                </p>
              </div>
              <div>
                <div className={'text-[17px] font-semibold leading-[24px]'}>
                  4. Exclusive Access
                </div>
                <p className={'text-[17px] text-[#525252] pt-1 pl-4 leading-[24px]'}>
                  You can see how influential you are in expanding our collective efforts to fight climate change.
                </p>
              </div>
            </div>
            <div className={'w-[30%] bg-[#CEF7C6] gap-6 flex flex-col rounded-[12px] px-10 py-8 text-start'}>
              <div className={'font-semibold text-[#1F6631] font-Inter text-[28px] leading-[32px]'}>
                Your Impact
              </div>
              <hr className={'border-[#00000018]'} />
              <div>
                <div className={'font-semibold text-[24px] leading-[32px] text-[#1F6631]'}>
                  7 Visits
                </div>
                <p className={'text-[15px] leading-[20px] text-[#00000065]'}>
                  unique<br/> visits made by<br/> your link
                </p>
              </div>
              <hr className={'border-[#00000018]'} />
              <div>
                <div className={'font-semibold text-[24px] leading-[32px] text-[#1F6631]'}>
                  0 Investors
                </div>
                <p className={'text-[15px] leading-[20px] text-[#00000065]'}>
                  unique people who made investment by your link
                </p>
              </div>
              <hr className={'border-[#00000018]'} />
              <div>
                <div className={'font-semibold text-[24px] leading-[32px] text-[#1F6631]'}>
                  0 CHF Invested
                </div>
                <p className={'text-[15px] leading-[20px] text-[#00000065]'}>
                  invested through your influence
                </p>
              </div>
              <hr className={'border-[#00000018]'} />
              <div>
                <div className={'font-semibold text-[24px] leading-[32px] text-[#1F6631]'}>
                  0 tons of CO2 reduced
                </div>
                <p className={'text-[15px] leading-[20px] text-[#00000065]'}>
                  greenhouse gas emissions that are offset through your influence
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer isMargin={false} />
    <div className={'md:hidden'}>
      <MobileFooter />
    </div>
  </div>)
}

export default ReferralPage