import React from 'react';

interface InvestmentBlockProps {
  name: string;
  amount: string;
  term: string;
  returnRate: string;
  imgSrc: string;
}

const InvestmentBlock: React.FC<InvestmentBlockProps> = ({ name, amount, term, returnRate, imgSrc }) => {
  return (
    <div className="flex items-center bg-[#F2F2EF] rounded-lg shadow-md w-full">
      <div className="w-1/4">
        <img src={imgSrc} alt={name} className="rounded-l-lg" />
      </div>
      <div className="w-3/4 pl-4 p-6">
        <h2 className="text-[28px] leading-[32px] font-bold text-start font-Inter">{name}</h2>
        <div className="flex justify-between items-stretch mt-3 bg-white rounded-[12px] p-4">
          <div className={' flex flex-col w-[28%]  text-start '}>
            <p className="text-gray-500 text-sm font-Inter">AMOUNT</p>
            <p className="text-lg font-semibold font-Inter">{amount}</p>
          </div>
          <div className={' mx-4 w-[1px] bg-[#D9D9D9]'}/>
          <div className={' flex flex-col w-[28%]  text-start'}>
            <p className="text-gray-500 text-sm font-Inter text-start">TERM</p>
            <p className="text-lg font-semibold font-Inter">{term}</p>
          </div>
          <div className={'mx-4 w-[1px] bg-[#D9D9D9]'}/>
          <div className={' flex flex-col w-[28%]  text-start'}>
            <p className="text-gray-500 text-sm font-Inter text-start">RETURN</p>
            <p className="text-lg font-semibold font-Inter">{returnRate}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentBlock;
