import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import config from './config'
import ReactGA from 'react-ga4'
import quizStore from './stores/answers'
import Survey from './pages/Quiz/Survey'
import { useQuery } from 'react-query'
import { getSurvey } from './utils/api'
import Results from './pages/Results'
import LoaderPulse from './components/LoaderPulse'
import SuccessForm from './pages/Results/SuccessForm'
import ClearCache from './pages/ClearCache'
import { Helmet } from 'react-helmet-async'
import RedirectPage from './pages/RedirectPage'
import ReactPixel from 'react-facebook-pixel'
import CheckTransactionScreen from './pages/CheckTransactionScreen'
import ProfileScreen from './pages/Profile/PrivateProfile'
import Login from './pages/Login'
import './utils/i18n'
import TranslationPage from './pages/TranslationPage'
import EmptyScreen from './pages/EmptyScreen'
import AboutUs from './pages/AboutUs'
import RiskDisclosure from './pages/DocumentsPages/RiskDisclosure'
import TermsPage from './pages/DocumentsPages/Terms'
import PrivacyPolicyPage from './pages/DocumentsPages/PrivacyPolicy'
import PublicProfile from './pages/Profile/PublicProfile'
import ProfileAchievements from './pages/Profile/ProfileAchievements'
import ProjectTemplate from './pages/ProjectTemplate'
import { mockProjects } from './pages/ProjectTemplate/helpers/mock'
import Projects from './pages/Projects'
import OurMission from './pages/OurMission'
import HowItWorks from './pages/HowItWorks'
import LandingV3 from './pages/LandingV3'
import LandingV4 from './pages/LandingV4'
import ResultsV2 from './pages/ResultsV2'
import FormETF from './pages/FormETF'
import CheckOutFlow from './pages/CheckOutFlow'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import IntroSurvey from './pages/IntroSurvey'
import usePageViewTimer from './utils/usePageViewTimer'
import RequireNewPassword from './pages/RequireNewPassword'
import ResetPassword from './pages/ResetPassword'
import Investment from './pages/Investment'
import Cookies from 'js-cookie'
import ReferralPage from './pages/Profile/Referral'


const MetaPixel = ({ fbPixelId }: { fbPixelId: string }) => {
  const fbPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${fbPixelId});
    fbq('track', 'PageView');
  `
  return (
    <Helmet>
      <script id='facebook-pixel-script'>{fbPixelScript}</script>
      <noscript id='facebook-pixel-image'>
        {`
        <img
        alt='facebook-pixel'
        height='1'
        width='1'
        class='hidden'
        src='https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1'
      />
        `}
      </noscript>
    </Helmet>
  )
}


const MouseFlow = () => {
  const mouseFlowId = 'fb5270f3-061e-431e-b51a-aa8107995d64'
  const mouseFlowScript = `
    window._mfq = window._mfq || [];
    (function() {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/${mouseFlowId}.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  `
  return (
    <Helmet>
      <script type='text/javascript'>
        {mouseFlowScript}
      </script>
    </Helmet>
  )
}


function App() {
  const GOOGLE_ANALYTICS_ID = config?.reactGAKey || ' '
  const searchParams = new URLSearchParams(window.location.search)
  const referenceCode = searchParams.get('ref')
  const height = window.visualViewport?.height || window.innerHeight
  const location = useLocation()
  const variantAbTest = localStorage.getItem('abtest') || ''
  const [ABTest] = useState(
    variantAbTest || (Math.floor(Math.random() * 5) + 1).toString(),
  )
  const hasGAInitialized = useRef(false)
  const [ip, setIp] = useState(localStorage.getItem('ip'))
  const [referenceId, setReferenceId] = useState(localStorage.getItem('referenceId') || '')
  const [hasSentSnowball, setHasSentSnowball] = useState(false)


  if (!variantAbTest) localStorage.setItem('abtest', ABTest)

  usePageViewTimer()

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location])

  useEffect(() => {
    if (!ip) {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('ip', data.ip)
          setIp(data.ip)
        })
        .catch(error => console.error('Error fetching :', error))
    }
  }, [ip])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const urlReferenceId = queryParams.get('referenceId')
    const forward = queryParams.get('forward')
    const fetchedAmount = Cookies.get('deal_amount') || '10000'
    const fetchedTerm = Cookies.get('deal_term') || '5'
    const fetchedReturnRate = Cookies.get('deal_return') || '7'

    localStorage.setItem('fetchedAmount', fetchedAmount)
    localStorage.setItem('fetchedTerm', fetchedTerm)
    localStorage.setItem('fetchedReturnRate', fetchedReturnRate)
    if (urlReferenceId && urlReferenceId !== referenceId && !hasSentSnowball) {
      localStorage.setItem('referenceId', urlReferenceId)
      setReferenceId(urlReferenceId)
      setHasSentSnowball(true)
    }
    if (forward && urlReferenceId) {
      switch (forward) {
      case 'projects':
        window.location.href = 'https://projects.pyrpose.io'
        break
      case 'project_qanta':
        window.location.href = 'https://projects.pyrpose.io/qanta'
        break
      default :
        return undefined
      }
    }
  }, [location.search, referenceId, hasSentSnowball])


  if (!hasGAInitialized.current) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {})
    hasGAInitialized.current = true
  }

  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  }

  ReactPixel.init(config?.fbPixel || '', undefined, options)

  const isClimateUrl =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('climate') ||
    window.location.hostname == 'pyrpose.io' ||
    window.location.hostname.includes('pyrpose-io.pages.dev') ||
    window.location.hostname.includes('ngrok')

  const { isLoading } = useQuery(
    ['questions'],
    async () => {
      await getSurvey(referenceCode).then((res) => {
        quizStore.setQuestions(res || [])
        return res
      })
    },
    {
      refetchOnWindowFocus: true,
    },
  )

  if (isLoading) {
    return <LoaderPulse />
  }

  const RedirectToExternal = () => {
    useEffect(() => {
      window.location.href = 'https://projects.pyrpose.io/howitworks'
    }, [])

    return null
  }

  return (
    <div
      className={'text-center flex w-full !sm:min-h-screen'}
      style={{
        minHeight: `${height}px`,
      }}
    >
      <MouseFlow />
      <MetaPixel fbPixelId={config?.fbPixel || ''} />
      <ToastContainer />
      <img
        height='1'
        alt={'facebook-pixel'}
        width='1'
        className='hidden'
        src={`https://www.facebook.com/tr?id=${config?.fbPixel || ''}&ev=PageView&noscript=1`}
      />

      {isClimateUrl ? (
        <Routes>
          <Route path={'/'} element={<LandingV4 />} />
          <Route path={'/v3'} element={<LandingV3 />} />
          <Route path={'/about-us'} element={<AboutUs />} />
          <Route path={'/carers'} element={<EmptyScreen />} />
          <Route path={'/faq'} element={<EmptyScreen />} />
          <Route path={'/investors'} element={<EmptyScreen />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/mission'} element={<OurMission />} />
          <Route path={'/payment-check'} element={<CheckTransactionScreen />} />
          <Route path={'/start'} element={<IntroSurvey />} />
          <Route path={'/privacy-policy'} element={<PrivacyPolicyPage />} />
          <Route path={'/profile'} element={<ProfileScreen />} />
          <Route path={'/referral'} element={<ReferralPage />} />
          <Route path={'/profile-achievements'} element={<ProfileAchievements />} />
          <Route path={'/projects'} element={<Projects />} />
          <Route path={'/risk-disclosure'} element={<RiskDisclosure />} />
          <Route path={'/terms-of-use'} element={<TermsPage />} />
          <Route path={'/invite'} element={<PublicProfile />} />
          <Route path={'/how-it-works'} element={<RedirectToExternal />} />
          <Route path={'/results'} element={<ResultsV2 />} />
          <Route path={'/survey'} element={<Survey />} />
          <Route path={'/survey/:index'} element={<Survey />} />
          <Route path={'/check-etfs'} element={<FormETF />} />
          <Route path={'/checkout'} element={<CheckOutFlow />} />
          <Route path={'/signup'} element={<CheckOutFlow />} />
          <Route path={'/investment'} element={<Investment />} />
          <Route path={'/request-password-change'} element={<RequireNewPassword />} />
          <Route path={'/reset-password'} element={<ResetPassword />} />
          {mockProjects.map((project) => (
            <Route path={'projects' + project.url} element={<ProjectTemplate data={project.data} />}
                   key={project.id} />
          ))}
          <Route path={'*'} element={<EmptyScreen />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={'/'} element={<RedirectPage />} />
          <Route path={'/about-us'} element={<AboutUs />} />
          <Route path={'/clear-cache'} element={<ClearCache />} />
          <Route path={'/invite'} element={<PublicProfile />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/mission'} element={<OurMission />} />
          <Route path={'/payment-check'} element={<CheckTransactionScreen />} />
          <Route path={'/privacy-policy'} element={<PrivacyPolicyPage />} />
          <Route path={'/profile'} element={<ProfileScreen />} />
          <Route path={'/profile-achievements'} element={<ProfileAchievements />} />
          <Route path={'/results'} element={<Results />} />
          <Route path={'/results/success'} element={<SuccessForm />} />
          <Route path={'/risk-disclosure'} element={<RiskDisclosure />} />
          <Route path={'/survey'} element={<Survey />} />
          <Route path={'/survey/:index'} element={<Survey />} />
          <Route path={'/terms-of-use'} element={<TermsPage />} />
          <Route path={'/how-it-works'} element={<HowItWorks />} />
          {
            process.env.NODE_ENV === 'development' &&
            <Route path={'/admin'} element={<TranslationPage />} />}
          <Route path={'*'} element={<RedirectPage />} />
        </Routes>
      )}
    </div>
  )
}

export default App
